import {
    getTitolFormatterFromApi,
    getTitolsFormatterFromApi,
    postTitolFormatterToApi,
    putTitolFormatterToApi
} from "../../formatters/titolsFormatter";
import {createTitolApi, deleteTitolApi, getTitolApi, getTitolsApi, updateTitolApi} from "../../api/data/titolsApi";


export const getTitolsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getTitolsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTitolsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all titols");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getTitolService = (autorId) => {
    return new Promise((resolve, reject) => {
        getTitolApi(autorId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTitolFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single data");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateTitolService = (autorId, compositor) => {
    return new Promise((resolve, reject) => {
        updateTitolApi(autorId, putTitolFormatterToApi(compositor))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTitolFormatterFromApi(response));
                } else {
                    throw new Error("Error editing compositor");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createTitolService = (compositor) => {
    return new Promise((resolve, reject) => {
        createTitolApi(postTitolFormatterToApi(compositor))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getTitolFormatterFromApi(response));
                } else {
                    throw new Error("Error creating compositor");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

/* export const uploadTitolFilesService = (file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadTitolFilesApi(file, fileName)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(response);
                } else {
                    throw new Error("Uploading file error");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};*/

export const deleteTitolsService = (codis) => {
    const promises = [];
    codis.forEach(codi => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteTitolApi(codi)
                    .then((res) => {
                        if (res.status === 200) {
                            const response = res.data;
                            if (response) resolve((response));
                        } else {
                            throw new Error("Error deleting titol " + codi);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
