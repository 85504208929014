import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getBlogsApi = (page = 1, search, itemsPerPage) => {
    let url = "/blogs?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteBlogApi = (postId) => {
    return axiosService.delete(`/blogs/${postId}`);
};

export const getBlogApi = (postId) => {
    return axiosService.get(`/blogs/${postId}`);
};

export const updateBlogApi = (postId, blog) => {
    return axiosService.put(`/blogs/${postId}`, blog);
};

export const createBlogApi = (blog) => {
    return axiosService.post(`/blogs`, blog);
};

export const uploadBlogFilesApi = (file, fileName) => {
    return axiosService.post("/blogs/upload", file, fileName, {headers: headers});
};
