import {
    getVinclesFormatterFromApi,
    getVincleFormatterFromApi,
    putVincleFormatterToApi,
    postVincleFormatterToApi
} from "./../../formatters/vinclesFormatter";
import {createVincleApi, deleteVincleApi, getVincleApi, getVinclesApi, updateVincleApi} from "../../api/data/vinclesApi";


export const getVinclesService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getVinclesApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getVinclesFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all vincles");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getVincleService = (vincleId) => {
    return new Promise((resolve, reject) => {
        getVincleApi(vincleId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getVincleFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single vincle");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateVincleService = (vincleId, vincle) => {
    return new Promise((resolve, reject) => {
        updateVincleApi(vincleId, putVincleFormatterToApi(vincle))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getVincleFormatterFromApi(response));
                } else {
                    throw new Error("Error editing vincle");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createVincleService = (vincle) => {
    return new Promise((resolve, reject) => {
        createVincleApi(postVincleFormatterToApi(vincle))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getVincleFormatterFromApi(response));
                } else {
                    throw new Error("Error creating vincle");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteVinclesService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteVincleApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting vincle " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
