import axiosService from "./../../services/axiosService";

export const getUsuarisApi = (page = 1, search, itemsPerPage) => {
    let url = "/usuaris?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteUsuariApi = (usuariId) => {
    return axiosService.delete(`/usuaris/${usuariId}`);
};

export const getUsuariApi = (usuariId) => {
    return axiosService.get(`/usuaris/${usuariId}`);
};

export const updateUsuariApi = (usuariId, usuari) => {
    return axiosService.put(`/usuaris/${usuariId}`, usuari);
};

export const createUsuariApi = (usuari) => {
    return axiosService.post(`/usuaris`, usuari);
};
