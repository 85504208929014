import axiosService from "./../../services/axiosService";

export const getVinclesApi = (page = 1, search, itemsPerPage) => {
    let url = "/vincles?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteVincleApi = (vincleId) => {
    return axiosService.delete(`/vincles/${vincleId}`);
};

export const getVincleApi = (vincleId) => {
    return axiosService.get(`/vincles/${vincleId}`);
};

export const updateVincleApi = (vincleId, vincle) => {
    return axiosService.put(`/vincles/${vincleId}`, vincle);
};

export const createVincleApi = (vincle) => {
    return axiosService.post(`/vincles`, vincle);
};
