import React from 'react';
import {Box, CircularProgress} from "@mui/material";


function CircularIndeterminate() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
            }}
        >
            <CircularProgress/>
        </Box>
    );
}

export default CircularIndeterminate;
