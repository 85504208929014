import axiosService from "./../../services/axiosService";

export const getTracksApi = (page = 1, search, itemsPerPage) => {
    let url = "/tracks?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteTrackApi = (trackId) => {
    return axiosService.delete(`/tracks/${trackId}`);
};

export const getTrackApi = (trackId) => {
    return axiosService.get(`/tracks/${trackId}`);
};

export const updateTrackApi = (trackId, track) => {
    return axiosService.put(`/tracks/${trackId}`, track);
};

export const createTrackApi = (track) => {
    return axiosService.post(`/tracks`, track);
};
