import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getCollaboradorsApi = (page = 1, search, itemsPerPage) => {
    let url = "/collaboradors?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteCollaboradorApi = (collaboradorId) => {
    return axiosService.delete(`/collaboradors/${collaboradorId}`);
};

export const getCollaboradorApi = (collaboradorId) => {
    return axiosService.get(`/collaboradors/${collaboradorId}`);
};

export const updateCollaboradorApi = (collaboradorId, collaborador) => {
    return axiosService.put(`/collaboradors/${collaboradorId}`, collaborador);
};

export const createCollaboradorApi = (collaborador) => {
    return axiosService.post(`/collaboradors`, collaborador);
};

export const uploadCollaboradorFilesApi = (file, fileName) => {
    return axiosService.post("/data/upload", file, fileName, {headers: headers});
};
