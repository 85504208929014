import React from "react";

import {THEMES} from "../constants";

const initialState = {
    theme: THEMES.DEFAULT,
    setTheme: (theme) => {
    },
};
const ThemeContext = React.createContext(initialState);

function ThemeProvider({children}) {

    return (
        <ThemeContext.Provider value={THEMES.DEFAULT}>
            {children}
        </ThemeContext.Provider>
    );
}

export {ThemeProvider, ThemeContext};
