import { getMaterialsDescripcioService } from "./../materialsDescripcio/materialsDescripcioService";
import {
  getMaterialsService,
  getMaterialService,
} from "./../materials/materialsService";

export const getMaterialsCommonService = (_page, search, itemsPerPage) => {
  const promises = [];

  promises.push(
    getMaterialsDescripcioService(),
    getMaterialsService(_page, search, itemsPerPage)
  );

  return Promise.all(promises)
    .then((r) => {
      let descripcio = r[0];
      let materials = r[1];
      return getAllDataFormatterFromApi(descripcio, materials);
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
};

export const getMaterialCommonService = (id) => {
  const promises = [];

  promises.push(getMaterialsDescripcioService(), getMaterialService(id));

  return Promise.all(promises)
    .then((r) => {
      let descripcio = r[0];
      let material = r[1];
      return getDataFormatterFromApi(descripcio, material);
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
};

export const getMaterialDescripcioCommonService = () => {
    const promises = [];
  
    promises.push(getMaterialsDescripcioService());
  
    return Promise.all(promises)
      .then((r) => {
        const materialDescripcio = r[0];
        return materialDescripcioFormatter(materialDescripcio);
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  };

// FORMATTERS
const getAllDataFormatterFromApi = (descripcio, materials) => {
  const results = [];

  const count = materials.count;

  materials.materials.forEach((material) => {
    results.push(getDataFormatterFromApi(descripcio, material));
  });
  return {
    count,
    results,
  };
};

const getDataFormatterFromApi = (descripcio, material) => {
  return {
    ...material,
    descripcio: descripcio.find((d) => d.id === +material.idDesc).descripcio,
  };
};

function materialDescripcioFormatter(materialDescripcio) {
    let materialDesc = [];
    materialDescripcio?.forEach((item) => {
      materialDesc.push({
        optionValue: item.id,
        optionItem: item.descripcio,
      });
    });
    return materialDesc;
  }
