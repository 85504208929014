import {
    createCompositorApi,
    getCompositorApi,
    getCompositorsApi,
    updateCompositorApi,
    uploadCompositorFilesApi
} from "../../api/data/compositorsApi";
import {
    getCompositorFormatterFromApi,
    getCompositorsFormatterFromApi,
    postCompositorFormatterToApi,
    putCompositorFormatterToApi
} from "../../formatters/compositorsFormatter";


export const getCompositorsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getCompositorsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCompositorsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all compositors");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getCompositorService = (autorId) => {
    return new Promise((resolve, reject) => {
        getCompositorApi(autorId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCompositorFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single compositors");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateCompositorService = (autorId, compositor) => {
    return new Promise((resolve, reject) => {
        updateCompositorApi(autorId, putCompositorFormatterToApi(compositor))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCompositorFormatterFromApi(response));
                } else {
                    throw new Error("Error editing compositor");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createCompositorService = (compositor) => {
    return new Promise((resolve, reject) => {
        createCompositorApi(postCompositorFormatterToApi(compositor))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getCompositorFormatterFromApi(response));
                } else {
                    throw new Error("Error creating compositor");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const uploadCompositorFilesService = (file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadCompositorFilesApi(file, fileName)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(response);
                } else {
                    throw new Error("Uploading file error");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};
