import {getLoginApi, getLogoutApi} from "./../../api/auth/authApi";

export const getLoginService = (username, password) => {
    return new Promise((resolve, reject) => {
        getLoginApi(username, password)
            .then((res) => {
                if (res.status === 200) {
                    const response = res;
                    if (response) resolve(response);
                } else {
                    throw new Error("Error logging in");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getLogoutService = () => {
    return new Promise((resolve, reject) => {
        getLogoutApi()
            .then((res) => {
                if (res.status === 200) {
                    const response = res;
                    if (response) resolve(response);
                } else {
                    throw new Error("Error logging in");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};
