import axios from "axios";

const axiosService = axios.create({
    baseURL: "/api",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

axiosService.interceptors.response.use(null, async (error) => {
    if (error.response.status === 401) {
        window.location.replace("/login");
    }
    return Promise.reject(error);
});

export default axiosService;
