import {
    createCdActuacioApi,
    getCdActuacioApi,
    getCdsActuacionsApi,
    updateCdActuacioApi,
    deleteCdActuacioApi
} from "../../api/data/cdsActuacionsApi";
import {
    getCdActuacioFormatterFromApi,
    getCdsActuacionsFormatterFromApi,
    postCdActuacioFormatterToApi,
    putCdActuacioFormatterToApi
} from "../../formatters/cdsActuacionsFormatter";


export const getCdsActuacionsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getCdsActuacionsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdsActuacionsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all cds actuacions");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getCdActuacioService = (cdActuacioId) => {
    return new Promise((resolve, reject) => {
        getCdActuacioApi(cdActuacioId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdActuacioFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single cd altre");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateCdActuacioService = (cdActuacioId, cdActuacio) => {
    return new Promise((resolve, reject) => {
        updateCdActuacioApi(cdActuacioId, putCdActuacioFormatterToApi(cdActuacio))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdActuacioFormatterFromApi(response));
                } else {
                    throw new Error("Error editing cdActuacio");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createCdActuacioService = (cdActuacio) => {
    return new Promise((resolve, reject) => {
        createCdActuacioApi(postCdActuacioFormatterToApi(cdActuacio))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getCdActuacioFormatterFromApi(response));
                } else {
                    throw new Error("Error creating cdActuacio");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};


export const deleteCdsActuacionsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteCdActuacioApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                           resolve();
                        } else {
                            throw new Error("Error deleting cdActuacio " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
