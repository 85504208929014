import React, {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {createTitolService, updateTitolService} from "../../data/services/titols/titolsService";
import {getTitolCommonService, getTipusCommonService, getInstrumentsCommonService, getCompositorsInfoCommonService } from "../../data/services/common/titolsCommonService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {Breadcrumbs, Button, Card, CardContent, CircularProgress, Grid, Snackbar, Typography} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import {Alert} from "@mui/lab";

const TitolsDetail = () => {

    const navigate = useNavigate();
    const [titol, setTitol] = useState({});
    const [tipus, setTipus] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [compositorInfo, setCompositorInfo] = useState([]);
    console.log("compositorInfo", compositorInfo);

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const {id} = useParams();
    const {state} = useLocation();

    const [inputs, setInputs] = useState({
        id: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Codi",
                disabled: true,
                value: "",
                onChange: handleChangeInputs
            }
        },
        titol: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Títol",
                value: "",
                onChange: handleChangeInputs
            }
        },
        autor: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Autor",
                value: "",
                onChange: handleChangeInputs
            }
        },
        arranjador: {
            type: CustomTextFieldTypes.SELECT,
            options: {
                label: "Arranjador",
                value: "",
                onChange: handleChangeInputs,
                // dropdownOptions: compositorInfo,
                dropdownOptions: [
                    {optionItem: "nom", optionValue: "1"},
                    {optionItem: "nom1", optionValue: "2"},
                    {optionItem: "nom2", optionValue: "3"}
                ],
            }
        },
        data: {
            type: CustomTextFieldTypes.YEAR,
            options: {
                label: "Any",
                value: "",
                onChange: handleChangeInputs
            }
        },
        instrument: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Instrumentació",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: instruments
            }
        },
        descripcioTipus: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Tipus",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: tipus,
            }
        },
        uriSpotify: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "URL spotify",
                value: "",
                onChange: handleChangeInputs
            }
        }
    })

    useEffect(() => {
        if (id !== "new") fetchData()
    }, [id]);

    const fetchData = () => {
        setIsLoading(true)
        getTitolCommonService(id)
            .then((titol) => {
                parseData(titol)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error obtenint el titol");
                setSnackbar(true);
                setIsLoading(false)
            })
        getTipusCommonService().then((res) => setTipus(res))
        getInstrumentsCommonService().then((res) => setInstruments(res))
        getCompositorsInfoCommonService().then(res => setCompositorInfo(res))
    }

    const parseData = (titol) => {
        const newInputs = {...inputs};
        Object.keys(newInputs).forEach((key) => {
            newInputs[key].options.value = titol[key];
        })
        setInputs(newInputs)
        setTitol(titol)
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function updateTitol() {
        const updatedTitol = titol;
        Object.keys(inputs).forEach((key) => {
            updatedTitol[key] = inputs[key].options.value;
        })
        setIsUpdating(true);

        if (id === "new") {
            createTitolService(updatedTitol)
                .then((titol) => {
                    parseData(titol)
                    setSnackbarSeverity("success");
                    setSnackbarText("Titol desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                    navigate(`/titols/${titol.id}`, {state: {nom: titol.nomSencer}});
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error creant el titol");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        } else {
            updateTitolService(id, updatedTitol)
                .then((titol) => {
                    parseData(titol)
                    setSnackbarSeverity("success");
                    setSnackbarText("Titol desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error desant el titol");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        setTitol({
            ...titol,
            [name]: value
        })
    }

    return (
        <div>
            <Tabs aria-label="nav tabs example" value={0} sx={{mb: 10}}>
                <Tab label="Editar títol"/>
                <Tab label="Materials" component={Link} to="materials"/>
            </Tabs>
            <>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <Button sx={{mb: 5}} variant="outlined" onClick={() => {
                        navigate("/titols")
                    }} style={{display: "flex", gap: 8}}>
                        <ArrowBackIosNewIcon size={20}/>
                        ENRERE
                    </Button>
                    <Button
                        sx={{mb: 5}}
                        disabled={isUpdating}
                        style={{color: 'white'}}
                        variant="contained"
                        onClick={updateTitol}
                    >
                        <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                            DESAR
                            {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                        </div>
                    </Button>
                </div>
                <Typography variant="h3" gutterBottom>
                    Detalls de {state?.nom}
                </Typography>
                <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                    <Link component={NavLink} to="/compositors">
                        Músics per la cobla
                    </Link>
                    <Link component={NavLink} to="/titols">
                        Titols
                    </Link>
                    <Typography>{state?.nom}</Typography>
                </Breadcrumbs>

                {isLoading ? (
                    <CircularIndeterminate/>
                ) : (
                    <>

                        <Card sx={{mb: 5}}>
                            <CardContent>
                                <Typography variant="h6" style={{marginBottom: 8}}>
                                    Dades
                                </Typography>
                                <Grid container spacing={8}>
                                    {Object.keys(inputs).map((key, index) => {
                                            const input = inputs[key];
                                            return (
                                                <Grid key={index} item md={4} style={{minWidth: 224}}>
                                                    <CustomTextField type={input.type}
                                                                     options={{...input.options, name: key}}/>
                                                </Grid>
                                            )
                                        }
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>


                        <Card sx={{mb: 5}}>
                            <ReactQuillEditor
                                titol="Dades complementaries"
                                value={titol?.dacomp}
                                handleChange={handleChange}
                                name="dacomp"
                            />
                        </Card>
                        <Card sx={{mb: 5}}>
                            <ReactQuillEditor
                                titol="Notes àudio"
                                value={titol?.notes1}
                                handleChange={handleChange}
                                name="notes1"
                            />
                        </Card>


                        <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                            <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                                {snackbarText}
                            </Alert>
                        </Snackbar>
                    </>
                )}
            </>
        </div>

    );
};
export default TitolsDetail;
