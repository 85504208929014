export const getDestacatsFormatterFromApi = (data) => {
    let rows = [];

    data["hydra:member"].forEach((item) => {
        rows.push(getDestacatFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        rows
    };
};

export const getDestacatFormatterFromApi = (item) => {
    return {
        id: item.id,
        titol: item.titol,
        subtitol: item.subtitol,
        ordre: item.ordre,
        imatge: item.imatge,
        link: item.link,
        active: item.active,
    };
};

export const putDestacatFormatterToApi = (item) => {
    return {
        id: item.id,
        titol: item.titol,
        subtitol: item.subtitol,
        ordre: parseInt(item.ordre),
        imatge: item.imatge,
        link: item.link,
        active: item.active,
    };
};


export const postDestacatFormatterToApi = (item) => {
    return {
        id: item.id,
        titol: item.titol,
        subtitol: item.subtitol,
        ordre: parseInt(item.ordre),
        imatge: item.imatge,
        link: item.link,
        active: item.active,
    };
};
