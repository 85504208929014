export const getBlogsFormatterFromApi = (data) => {
    let blogs = [];

    data["hydra:member"].forEach((item) => {
        blogs.push(getBlogFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        blogs
    };
};

export const getBlogFormatterFromApi = (item) => {
    
    const date = new Date(item.postdate).toLocaleDateString('es-ES');

    return {
        id: item.postid,
        postTitol: item.posttitle,
        postSlug: item.postslug,
        descripcio: item.postdesc,
        contingut: item.postcont,
        data: date
    };
};

export const putBlogFormatterToApi = (item) => {
    return {
        postid: item.id,
        posttitle: item.postTitol,
        postslug: item.postSlug,
        postdesc: item.descripcio,
        postcont: item.contingut,
        postdate: item.data,
    };
};


export const postBlogFormatterToApi = (item) => {
    return {
        postid: item.id,
        posttitle: item.postTitol,
        postslug: item.postSlug,
        postdesc: item.descripcio,
        postcont: item.contingut,
        postdate: item.data,
    };
};
