import {
    getDestacatFormatterFromApi,
    getDestacatsFormatterFromApi,
    postDestacatFormatterToApi,
    putDestacatFormatterToApi
} from "../../formatters/destacatsFormatter";
import {createDestacatApi, deleteDestacatApi, getDestacatApi, getDestacatsApi, updateDestacatApi, uploadDestacatFilesApi} from "../../api/data/destacatsApi";


export const getDestacatsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getDestacatsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getDestacatsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all destacats");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getDestacatService = (destacatId) => {
    return new Promise((resolve, reject) => {
        getDestacatApi(destacatId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getDestacatFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single destacat");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateDestacatService = (destacatId, destacat) => {
    return new Promise((resolve, reject) => {
        updateDestacatApi(destacatId, putDestacatFormatterToApi(destacat))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getDestacatFormatterFromApi(response));
                } else {
                    throw new Error("Error editing destacat");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createDestacatService = (destacat) => {
    return new Promise((resolve, reject) => {
        createDestacatApi(postDestacatFormatterToApi(destacat))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getDestacatFormatterFromApi(response));
                } else {
                    throw new Error("Error creating destacat");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const uploadDestacatFilesService = (file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadDestacatFilesApi(file, fileName)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(response);
                } else {
                    throw new Error("Uploading file error");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteDestacatsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteDestacatApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting destacat " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
