export const getMaterialsFormatterFromApi = (data) => {
    let materials = [];

    data["hydra:member"].forEach((item) => {
        materials.push(getMaterialFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        materials
    };
};

export const getMaterialFormatterFromApi = (item) => {
    // TODO: FER CRIDA DE LA API PER A OBTENIR LA DESCRIPCIÓ
    return {
        id: item.idMaterial,
        codi: item.codi,
        referencia: item.referencia,
        tipus: item.tipus,
        idDesc: parseInt(item.idDesc),
        notes: item.notes,
        pdf: item.pdfDescarregable
    };
};

export const putMaterialFormatterToApi = (item) => {
    return {
        idMaterial: item.id,
        codi: item.codi,
        referencia: item.referencia,
        tipus: item.tipus,
        idDesc: item.idDesc?.toString(),
        notes: item.notes,
        pdfDescarregable: item.pdf
    };
};


export const postMaterialFormatterToApi = (item) => {
    return {
        idMaterial: item.id,
        codi: item.codi,
        referencia: item.referencia,
        tipus: item.tipus,
        idDesc: item.idDesc?.toString(),
        notes: item.notes,
        pdfDescarregable: item.pdf
    };
};
