import axiosService from "./../../services/axiosService";

export const getCdsAltresApi = (page = 1, search, itemsPerPage) => {
    let url = "/cds-altres?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const getCdAltreApi = (cdAltreId) => {
    return axiosService.get(`/cds-altres/${cdAltreId}`);
};

export const updateCdAltreApi = (cdAltreId, cdAltre) => {
    return axiosService.put(`/cds-altres/${cdAltreId}`, cdAltre);
};

export const createCdAltreApi = (cdAltre) => {
    return axiosService.post(`/cds-altres`, cdAltre);
};

export const deleteCdAltreApi = (cdAltreId) => {
    return axiosService.delete(`/cds-altres/${cdAltreId}`);
};
