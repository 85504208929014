import React from "react";
import CdsAltres from "../CdsAltres/CdsAltresScreen";
import CdsMonografics from "../CdsMonografics/CdsMonograficsScreen";
import CdsActuacionsAltres from "../CdsActuacionsAltres/CdsActuacionsAltresScreen";


const Abreviatures = () => {
    return (
        <>
            <CdsMonografics/>
            <CdsAltres/>
            <CdsActuacionsAltres/>
        </>
    );
};
export default Abreviatures;
