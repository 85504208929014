import React, {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {createCollaboradorService, getCollaboradorService, updateCollaboradorService} from "../../data/services/collaboradors/collaboradorsService";
import { getCompositorsInfoCommonService } from "../../data/services/common/collaboradorsCommonService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import rolsJson from "./../../rols.json";
import {Breadcrumbs, Button, Card, CardContent, CircularProgress, Grid, Snackbar, Typography} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import {Alert} from "@mui/lab";

const getTipusRols = () => {
    let rol = [];
    rolsJson?.forEach((item) => {
        rol.push({
            optionValue: item.rol,
        });
    });
    return rol;
}

const rols = getTipusRols();

const CollaboradorDetails = () => {
    const navigate = useNavigate();
    const [collaborador, setCollaborador] = useState({});
    const [compositorInfo, setCompositorInfo] = useState([]);
    // console.log(compositorInfo);

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const {id} = useParams();
    const {state} = useLocation();

    const [inputs, setInputs] = useState({
        nom: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nom",
                disabled: true,
                value: "",
                onChange: handleChangeInputs
            }
        },
        rol: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Rol",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: rols
            }
        },
        autorId: {
            type: CustomTextFieldTypes.SELECT,
            options: {
                label: "Autor a la base de dades",
                value: "",
                onChange: handleChangeInputs,
                // dropdownOptions: compositorInfo
                dropdownOptions: [
                    {optionItem: "BLAY, Joan-Josep Exemple", optionValue: "150"},
                    {optionItem: "LEÓN, Jordi Exemple", optionValue: "340"},
                    {optionItem: "MOLINA, Jordi Exemple", optionValue: "324"},
                ]
            }
        },
    })

    useEffect(() => {
        if (id !== "new") fetchData()
    }, [id]);

    const fetchData = () => {
        setIsLoading(true)
        getCollaboradorService(id)
            .then((collaborador) => {
                parseData(collaborador)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error obtenint el collaborador");
                setSnackbar(true);
                setIsLoading(false)
            })
        getCompositorsInfoCommonService().then(res => setCompositorInfo(res))
    }

    const parseData = (collaborador) => {
        const newInputs = {...inputs};
        Object.keys(newInputs).forEach((key) => {
            newInputs[key].options.value = collaborador[key];
        })
        setInputs(newInputs)
        setCollaborador(collaborador)
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function updateCollaborador() {
        const updatedCollaborador = collaborador;
        Object.keys(inputs).forEach((key) => {
            updatedCollaborador[key] = inputs[key].options.value;
        })
        setIsUpdating(true);

        if (id === "new") {
            createCollaboradorService(updatedCollaborador)
                .then((collaborador) => {
                    parseData(collaborador)
                    setSnackbarSeverity("success");
                    setSnackbarText("collaborador desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                    navigate(`/collaboradors/${collaborador.id}`, {state: {nom: collaborador.nom}});
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error creant el collaborador");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        } else {
            updateCollaboradorService(id, updatedCollaborador)
                .then((collaborador) => {
                    parseData(collaborador)
                    setSnackbarSeverity("success");
                    setSnackbarText("Col·laborador desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error desant el collaborador");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        setCollaborador({
            ...collaborador,
            [name]: value
        })
    }

    return (
        <div>
            <Tabs aria-label="nav tabs example" value={0} sx={{mb: 10}}>
                <Tab label="Editar col·laborador"/>
                <Tab label="Abreviatures" component={Link} to="/abreviatures"/>
            </Tabs>
            <>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <Button sx={{mb: 5}} variant="outlined" onClick={() => {
                        navigate("/collaboradors")
                    }} style={{display: "flex", gap: 8}}>
                        <ArrowBackIosNewIcon size={20}/>
                        ENRERE
                    </Button>
                    <Button
                        sx={{mb: 5}}
                        disabled={isUpdating}
                        style={{color: 'white'}}
                        variant="contained"
                        onClick={updateCollaborador}
                    >
                        <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                            DESAR
                            {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                        </div>
                    </Button>
                </div>
                <Typography variant="h3" gutterBottom>
                    Detalls de {state?.nom}
                </Typography>
                <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                    <Link component={NavLink} to="/compositors">
                        Músics per la cobla
                    </Link>
                    <Link component={NavLink} to="/collaboradors">
                        Col·laboradors
                    </Link>
                    <Typography>{state?.nom}</Typography>
                </Breadcrumbs>

                {isLoading ? (
                    <CircularIndeterminate/>
                ) : (
                    <>

                        <Card sx={{mb: 5}}>
                            <CardContent>
                                <Typography variant="h6" style={{marginBottom: 8}}>
                                    Dades
                                </Typography>
                                <Grid container spacing={8}>
                                    {Object.keys(inputs).map((key, index) => {
                                            const input = inputs[key];
                                            return (
                                                <Grid key={index} item md={4} style={{minWidth: 224}}>
                                                    <CustomTextField type={input.type}
                                                                     options={{...input.options, name: key}}/>
                                                </Grid>
                                            )
                                        }
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>


                        <Card sx={{mb: 5}}>
                            <ReactQuillEditor
                                collaborador="Activitats"
                                value={collaborador?.activitats}
                                handleChange={handleChange}
                                name="activitats"
                            />
                        </Card>

                        <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                            <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                                {snackbarText}
                            </Alert>
                        </Snackbar>
                    </>
                )}
            </>
        </div>
    );
};
export default CollaboradorDetails;
