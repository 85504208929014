import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {spacing} from "@mui/system";
import {TextField as MuiTextField} from "@mui/material";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

const TextField = styled(MuiTextField)(spacing);
const filter = createFilterOptions();

const AutocompleteSelect = ({value, options, onChange, label}) => {
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue])

    function handleSelect(event, newValue) {
        setSelectedValue(newValue?.optionValue);

        if (typeof newValue === "string") {
            setSelectedValue(newValue);
        } else if (newValue && newValue.inputValue) {
            setSelectedValue(newValue.inputValue);
        }
    }

    return (
        <Autocomplete
            value={selectedValue}
            fullWidth
            onChange={handleSelect}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;

                const isExisting = options.some(
                    (option) => inputValue === option.optionValue
                );
                if (inputValue !== "" && !isExisting) {
                    filtered.push({
                        inputValue,
                        optionValue: `${inputValue}`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="optionValue"
            name="optionValue"
            options={options}
            getOptionLabel={(option) => {
                if (typeof option === "string") {
                    return option;
                }

                if (option.inputValue) {
                    return option.inputValue;
                }

                return option.optionValue;
            }}
            renderOption={(props, option) => (
                <li {...props}>{option.optionValue}</li>
            )}
            freeSolo
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        name="optionValue"
                    />
                );
            }}
        />
    );
};
export default AutocompleteSelect;
