import { getCompositorsInfoService } from "../compositorsInfo/compositorsInfoService";

export const getCompositorsInfoCommonService = () => {
    const promises = [];
  
    promises.push(getCompositorsInfoService());
  
    return Promise.all(promises)
      .then((r) => {
        const compositorsInfo = r[0];
        return compositorsInfoFormatter(compositorsInfo);
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  };


  function compositorsInfoFormatter(compositorsInfo) {
    let compositors = [];
    compositorsInfo?.forEach((item) => {
      compositors.push({
          optionValue: item.id,
          optionItem: item.nomSencer,
      });
    });
    return compositors;
  }
