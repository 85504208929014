import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate, useLocation} from "react-router-dom";
import {Breadcrumbs, Button, CircularProgress, Grid, Paper, Snackbar, Typography} from "@mui/material";
import Searchbar from "../../components/Layout/Searchbar";
import SmallButton from "../../components/unit/SmallButton";
import {FilterList as FilterListIcon} from "@mui/icons-material";
import DataGridMPC from "../../components/unit/DataGridMPC";
import columns from "./CdsActuacionsAltresColumns";
import {deleteCdsActuacionsService, getCdsActuacionsService} from "../../data/services/cdsActuacionsAltres/cdsActuacionsAltresService";
import {Alert} from "@mui/lab";


const CdsAltres = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [_page, _setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [idsToDelete, setIdsToDelete] = useState([]);

    const [isUpdating, setIsUpdating] = useState(false);


    function onRowClick(params) {
        navigate(`cds-actuacions-altres/${params.row.id}`, {state: {nom: params.row.titol}});
    }

    function onCreateClick() {
        navigate(`cds-actuacions-altres/new`, {state: {nom: "Nou cd altres", path: `${pathname}/cds-actuacions-altres`}});
    }

    function onDeleteClick() {
        const res = window.confirm("Segur que vols esborrar aquests cds altres?");
        if (!res) return;
        setIsUpdating(true);
        deleteCdsActuacionsService(idsToDelete)
            .then(() => {
                setSnackbarSeverity("success");
                setSnackbarText("Cds altres esborrats correctament");
                setSnackbar(true);
                setLoading(true);
                fetchData();
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error esborrant els cds altres");
                setSnackbar(true);
                setIsUpdating(false);
            })

    }

    const fetchData = () => {
        getCdsActuacionsService(_page, search, itemsPerPage)
            .then((res) => {
                setRows(res.actuacions)
                setCount(res.count);
                setLoading(false)
                setIsUpdating(false);
            });
    }


    useEffect(() => {
        setRows([])
        setLoading(true)
        fetchData()
    }, [_page, search, itemsPerPage]);

    const onPageChange = (page) => {
        _setPage(page + 1)
    }

    const onSearchChange = (str) => {
        setSearch(str.target.value);
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: "column"}}>
            <Grid justifyContent="space-between" container spacing={6} py={3}>
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        Altres activitats
                    </Typography>
                    <Grid>
                        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                            <Link component={NavLink} to="/compositors">
                                Músics per la cobla
                            </Link>
                            <Typography>Altres activitats</Typography>
                        </Breadcrumbs>
                        <Searchbar placeholder="Buscar altres activitats" onChange={onSearchChange}/>
                    </Grid>
                </Grid>
                <Grid item style={{marginTop: "81px"}}>
                    {idsToDelete.length > 0 && <Button
                        onClick={onDeleteClick}
                        variant="contained"
                        color="error"
                        disabled={isUpdating}
                    >
                        <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                            ESBORRAR
                            {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                        </div>
                    </Button>}
                    <SmallButton size="small" mr={2}>
                        <FilterListIcon/>
                    </SmallButton>
                    <Button
                        onClick={onCreateClick}
                        variant="contained"
                        color="secondary"
                    >
                        CREAR
                    </Button>
                </Grid>
            </Grid>

            <Paper style={{display: 'flex', height: '100%', flex: 1}}>
                <DataGridMPC
                    rowCount={count}
                    checkboxSelection
                    onSelectionModelChange={(data) => setIdsToDelete(data)}
                    onPageSizeChange={(pageSize) => {
                        setItemsPerPage(pageSize)
                    }}
                    onPageChange={onPageChange}
                    columns={columns}
                    rows={rows}
                    onRowClick={onRowClick}
                    loading={loading}
                    density={"compact"}
                />
            </Paper>
            <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </div>
    );
};
export default CdsAltres;
