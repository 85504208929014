import axiosService from "./../../services/axiosService";

export const getCdsMonograficsApi = (page = 1, search, itemsPerPage) => {
    let url = "/cds-monografics?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const getCdMonograficApi = (cdMonograficId) => {
    return axiosService.get(`/cds-monografics/${cdMonograficId}`);
};

export const updateCdMonograficApi = (cdMonograficId, cdMonografic) => {
    return axiosService.put(`/cds-monografics/${cdMonograficId}`, cdMonografic);
};

export const createCdMonograficApi = (cdMonografic) => {
    return axiosService.post(`/cds-monografics`, cdMonografic);
};

export const deleteCdMonograficApi = (cdMonograficId) => {
    return axiosService.delete(`/cds-monografics/${cdMonograficId}`);
};
