export const getCompositorsInfoFormatterFromApi = (data) => {
    let compositors = [];

    data["compositors"].forEach((item) => {
        compositors.push(getCompositorInfoFormatterFromApi(item));
    });

    return compositors;
};

export const getCompositorInfoFormatterFromApi = (item) => {
    return {
        id: item.autorId,
        nom: item.autorNom,
        cognom: item.autorCog,
        nomSencer: item.autorForm,
    };
};
