import React from "react";
import {useNavigate} from "react-router-dom";

import styled from "styled-components/macro";
import {IconButton as MuiIconButton} from "@mui/material";
import {Power} from "react-feather";
import {getLogoutService} from "./../../../data/services/auth/authService";

const Footer = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    background-color: ${(props) =>
        props.theme.sidebar.footer.background} !important;
    padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(4)};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

const SidebarFooter = ({...rest}) => {
    const navigate = useNavigate();

    return (
        <Footer {...rest}>
            <IconButton
                color="inherit"
                size="large"
                onClick={() => {
                    getLogoutService();
                    navigate("/login");
                }}
            >
                <p style={{fontSize: 16, marginRight: 8}}>Tancar sessió</p>
                <Power/>
            </IconButton>
        </Footer>
    );
};
export default SidebarFooter;
