import {
    getUsuarisFormatterFromApi,
    getUsuariFormatterFromApi,
    putUsuariFormatterToApi,
    postUsuariFormatterToApi
} from "../../formatters/usuarisFormatter";
import {createUsuariApi, deleteUsuariApi, getUsuariApi, getUsuarisApi, updateUsuariApi} from "../../api/data/usuarisApi";


export const getUsuarisService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getUsuarisApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getUsuarisFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all usuaris");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getUsuariService = (usuariId) => {
    return new Promise((resolve, reject) => {
        getUsuariApi(usuariId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getUsuariFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single user");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateUsuariService = (usuariId, usuari) => {
    return new Promise((resolve, reject) => {
        updateUsuariApi(usuariId, putUsuariFormatterToApi(usuari))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getUsuariFormatterFromApi(response));
                } else {
                    throw new Error("Error editing usuari");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createTitolService = (usuari) => {
    return new Promise((resolve, reject) => {
        createUsuariApi(postUsuariFormatterToApi(usuari))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getUsuariFormatterFromApi(response));
                } else {
                    throw new Error("Error creating usuari");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteUsuarisService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteUsuariApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting usuari " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
