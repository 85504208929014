import styled from "styled-components/macro";
import {Chip as MuiChip} from "@mui/material"

export const ChipTipus = styled(MuiChip)`
    .MuiChip-root {
        background-color: red !important;
        color: ${props => props.theme.palette.common.white};
    }
}
`
