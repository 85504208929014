const columns = [
    {
        field: "randomUser",
        headerName: "Correu",
        width: 200,
    },
    {
        field: "nomUsuari",
        headerName: "Nom",
        width: 120,
        editable: false,
    },
    {
        field: "alta",
        headerName: "Alta",
        width: 100,
    }
];
export default columns;
