import {
    getEnregistramentFormatterFromApi,
    getEnregistramentsFormatterFromApi,
    postEnregistramentFormatterToApi,
    putEnregistramentFormatterToApi
} from "../../formatters/enregistramentsFormatter";
import {createEnregistramentApi, deleteEnregistramentApi, getEnregistramentApi, getEnregistramentsApi, updateEnregistramentApi} from "../../api/data/enregistramentsApi";


export const getEnregistramentsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getEnregistramentsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getEnregistramentsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all enregistrament");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getEnregistramentService = (enregistramentId) => {
    return new Promise((resolve, reject) => {
        getEnregistramentApi(enregistramentId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getEnregistramentFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single enregistrament");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateEnregistramentService = (enregistramentId, enregistrament) => {
    return new Promise((resolve, reject) => {
        updateEnregistramentApi(enregistramentId, putEnregistramentFormatterToApi(enregistrament))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getEnregistramentFormatterFromApi(response));
                } else {
                    throw new Error("Error editing enregistrament");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createEnregistramentService = (enregistrament) => {
    return new Promise((resolve, reject) => {
        createEnregistramentApi(postEnregistramentFormatterToApi(enregistrament))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getEnregistramentFormatterFromApi(response));
                } else {
                    throw new Error("Error creating enregistrament");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

/* export const uploadEnregistramentFilesService = (file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadEnregistramentFilesApi(file, fileName)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(response);
                } else {
                    throw new Error("Uploading file error");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};*/

export const deleteEnregistramentsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteEnregistramentApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting enregistrament " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
