import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";

import {getLoginService} from "./../../data/services/auth/authService";
import Logo from "./../../assets/logo.png";

import {Alert as MuiAlert, Button, Paper, TextField as MuiTextField, Typography,} from "@mui/material";
import {spacing} from "@mui/system";


const Brand = styled.img`
    fill: ${(props) => props.theme.palette.primary.main};
    width: 90px;
    height: 90px;
    margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)};
    height: 550px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)};
    }
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Wrapper>
                <Brand src={Logo} alt="MCP logo"/>

                <Typography component="h1" variant="h4" align="center" gutterBottom>
                    Benviguts a MÚSICS PER LA COBLA
                </Typography>

                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().max(255).required("Si us plau escrigui el nom d'usuari"),
                        password: Yup.string().max(255).required("Si us plau escrigui la contrasenya"),
                    })}
                    onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                        try {
                            await getLoginService(values.username, values.password);
                            navigate("/compositors");
                        } catch (error) {
                            const message = "Usuari i/o contrasenya incorrecta, si us plau revisi els camps" || error.message;

                            setStatus({success: false});
                            setErrors({submit: message});
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                      }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            style={{
                                height: "250px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: "column",
                                alignSelf: "normal",
                            }}
                        >
                            {errors.submit && (
                                <Alert mt={2} mb={3} severity="warning">
                                    {errors.submit}
                                </Alert>
                            )}
                            <TextField
                                type="text"
                                name="username"
                                label="Nom"
                                value={values.username}
                                error={Boolean(touched.username && errors.username)}
                                fullWidth
                                helperText={touched.username && errors.username}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={10}
                            />
                            <TextField
                                type="password"
                                name="password"
                                label="Contrasenya"
                                value={values.password}
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={10}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                            >
                                Iniciar sessió
                            </Button>
                        </form>
                    )}
                </Formik>
            </Wrapper>
        </React.Fragment>
    );
}

export default SignIn;
