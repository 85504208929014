export const getCollaboradorsFormatterFromApi = (data) => {
    let collaborador = [];

    data["hydra:member"].forEach((item) => {
        collaborador.push(getCollaboradorFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        collaborador
    };
};

export const getCollaboradorFormatterFromApi = (item) => {
    return {
        id: item.idComponent,
        rol: item.rol,
        nom: item.nom,
        activitats: item.activitats,
        autorId: item.autorId,
    };
};

export const putCollaboradorFormatterToApi = (item) => {
    return {
        idComponent: item.id,
        rol: item.rol,
        nom: item.nom,
        activitats: item.activitats,
        autorId: parseInt(item.autorId),
    };
};


export const postCollaboradorFormatterToApi = (item) => {
    return {
        idComponent: item.id,
        rol: item.rol,
        nom: item.nom,
        activitats: item.activitats,
        autorId: parseInt(item.autorId),
    };
};
