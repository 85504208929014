export const getVinclesFormatterFromApi = (data) => {
    let vincles = [];

    data["hydra:member"].forEach((item) => {
        vincles.push(getVincleFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        vincles
    };
};

export const getVincleFormatterFromApi = (item) => {
    return {
        id: item.idLin,
        vincleUrl: item.urlLin,
        vincleNom: item.nameLin,
        vincleCategoria: item.catLin,
    };
};

export const putVincleFormatterToApi = (item) => {
    return {
        idLin: item.id,
        urlLin: item.vincleUrl,
        nameLin: item.vincleNom,
        catLin: item.vincleCategoria,
    };
};


export const postVincleFormatterToApi = (item) => {
    return {
        urlLin: item.vincleUrl,
        nameLin: item.vincleNom,
        catLin: item.vincleCategoria,
    };
};
