import {
    getInstrumentsFormatterFromApi,
} from "../../formatters/instrumentsFormatter";
import {getInstrumentsApi} from "../../api/data/instrumentsApi";


export const getInstrumentsService = () => {
    return new Promise((resolve, reject) => {
        getInstrumentsApi()
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getInstrumentsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all instruments");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};
