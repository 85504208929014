import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomTextField, {
  CustomTextFieldTypes,
} from "../../components/composed/CustomTextField";
import {
  createCdMonograficService,
  getCdMonograficService,
  updateCdMonograficService,
} from "../../data/services/cdsMonografics/cdsMonograficsService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import { Alert } from "@mui/lab";

const CdsMonograficsDetails = () => {
  const navigate = useNavigate();
  const [cdMonografic, setCdMonografic] = useState({});

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarText, setSnackbarText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { id } = useParams();
  const { state } = useLocation();
  const [inputs, setInputs] = useState({
    codi: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Codi",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
    any: {
      type: CustomTextFieldTypes.YEAR,
      options: {
        label: "Any",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
    titol: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Títol",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
    cdId: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Cd id",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
  });

  useEffect(() => {
    if (id !== "new") fetchData();
  }, [id]);

  const fetchData = () => {
    setIsLoading(true);
    getCdMonograficService(id)
      .then((cdMonografic) => {
        parseData(cdMonografic);
        setIsLoading(false);
      })
      .catch(() => {
        setSnackbarSeverity("error");
        setSnackbarText("Hi ha hagut un error obtenint el cd monògrafic");
        setSnackbar(true);
        setIsLoading(false);
      });
  };

  const parseData = (cdMonografic) => {
    const newInputs = { ...inputs };
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].options.value = cdMonografic[key];
    });
    setInputs(newInputs);
    setCdMonografic(cdMonografic);
  };

  function handleChangeInputs(event) {
    const value = event.target.value;
    const name = event.target.name;
    if (!inputs[name]) return;
    const newInputs = { ...inputs };
    newInputs[name].options.value = value;
    setInputs(newInputs);
  }

  function updateCdMonografic() {
    const updatedCdMonografic = cdMonografic;
    Object.keys(inputs).forEach((key) => {
      updatedCdMonografic[key] = inputs[key].options.value;
    });
    setIsUpdating(true);

    if (id === "new") {
      createCdMonograficService(updatedCdMonografic)
        .then((cdMonografic) => {
          parseData(cdMonografic);
          setSnackbarSeverity("success");
          setSnackbarText("Cd monogràfic desat correctament");
          setSnackbar(true);
          setIsUpdating(false);
          navigate(`${state.path}/${cdMonografic.id}`, {
            state: { nom: cdMonografic.titol },
          });
        })
        .catch(() => {
          setSnackbarSeverity("error");
          setSnackbarText("Hi ha hagut un error creant el cd monogràfic");
          setSnackbar(true);
          setIsUpdating(false);
        });
    } else {
      updateCdMonograficService(id, updatedCdMonografic)
        .then((cdMonografic) => {
          parseData(cdMonografic);
          setSnackbarSeverity("success");
          setSnackbarText("Cd monogràfic desat correctament");
          setSnackbar(true);
          setIsUpdating(false);
        })
        .catch(() => {
          setSnackbarSeverity("error");
          setSnackbarText("Hi ha hagut un error desant el cd monogràfic");
          setSnackbar(true);
          setIsUpdating(false);
        });
    }
  }

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ mb: 5 }}
            variant="outlined"
            onClick={() => {
              navigate("/abreviatures");
            }}
            style={{ display: "flex", gap: 8 }}
          >
            <ArrowBackIosNewIcon size={20} />
            ENRERE
          </Button>
          <Button
            sx={{ mb: 5 }}
            disabled={isUpdating}
            style={{ color: "white" }}
            variant="contained"
            onClick={updateCdMonografic}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              DESAR
              {isUpdating && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </div>
          </Button>
        </div>
        <Typography variant="h3" gutterBottom>
          Detalls de {state?.nom}
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
          <Link component={NavLink} to="/compositors">
            Músics per la cobla
          </Link>
          <Link component={NavLink} to={-1}>
            Cd monogràfic
          </Link>
          <Typography>{state?.nom}</Typography>
        </Breadcrumbs>

        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <Card sx={{ mb: 5 }}>
              <CardContent>
                <Typography variant="h6" style={{ marginBottom: 8 }}>
                  Dades
                </Typography>
                <Grid container spacing={8}>
                  {Object.keys(inputs).map((key, index) => {
                    const input = inputs[key];
                    return (
                      <Grid key={index} item md={4} style={{ minWidth: 224 }}>
                        <CustomTextField
                          type={input.type}
                          options={{ ...input.options, name: key }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>

            <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={() => setSnackbar(false)}
            >
              <Alert
                onClose={() => setSnackbar(false)}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarText}
              </Alert>
            </Snackbar>
          </>
        )}
      </>
    </div>
  );
};
export default CdsMonograficsDetails;
