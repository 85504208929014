import React from "react";
import {Box, CardContent, Typography} from "@mui/material";
import styled from "styled-components";

import ReactQuill, {Quill} from "react-quill";
import {htmlEditButton} from "quill-html-edit-button";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {uploadCompositorFilesService} from "../../data/services/compositors/compositorsService";

Quill.register("modules/htmlEditButton", htmlEditButton);

const QuillWrapper = styled.div`
    .ql-editor {
        min-height: 200px;
    }
`;

const modules = {
    toolbar: {
        container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            [{indent: "-1"}, {indent: "+1"}],
            ["link", "image"],

            [{size: ["small", false, "large", "huge"]}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],

            [{color: []}, {background: []}],
            [{font: []}],
            [{align: []}],
        ],
        handlers: {
            image: imageHandler,
        },
    },
    htmlEditButton: {
        msg: "Editar contingut en format HTML",
        okText: "Desar",
        cancelText: "Cancel·lar",
    },
};

let quillObj;

const FILE_UPLOAD = process.env.REACT_APP_FILES_FOLDER_PATH;

function imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
        try {
            const file = input.files[0];
            const formData = new FormData();
            formData.append("file", file, file.name);

            const range = quillObj.getEditorSelection();

            const res = await uploadCompositorFilesService(formData);

            quillObj
                .getEditor()
                .insertEmbed(range.index, "image", `${FILE_UPLOAD}/${res}`);
        } catch (error) {
            alert(error);
        }
    };
}

function ReactQuillEditor({titol = "", value = "", handleChange = () => null, name = ""}) {
    return (
        <CardContent>
            <Typography variant="h6" gutterBottom>
                {titol}
            </Typography>
            <Box mt={3}>
                <QuillWrapper>
                    <ReactQuill
                        ref={(el) => {
                            quillObj = el;
                        }}
                        theme="snow"
                        modules={modules}
                        value={value}
                        name={name}
                        onChange={(value) => {
                            handleChange({
                                target: {name: name, value: value},
                            });
                        }}
                    />
                </QuillWrapper>
            </Box>
        </CardContent>
    );
}

export default ReactQuillEditor;
