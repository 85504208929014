import {
    getAllTipusFormatterFromApi,
} from "../../formatters/tipusFormatter";
import {getTipusApi} from "../../api/data/tipusApi";


export const getTipusService = () => {
    return new Promise((resolve, reject) => {
        getTipusApi()
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getAllTipusFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all tipus");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};
