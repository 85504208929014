import axiosService from "./../../services/axiosService";

export const getMaterialsApi = (page = 1, search, itemsPerPage) => {
    let url = "/materials?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteMaterialApi = (materialId) => {
    return axiosService.delete(`/materials/${materialId}`);
};

export const getMaterialApi = (materialId) => {
    return axiosService.get(`/materials/${materialId}`);
};

export const updateMaterialApi = (materialId, material) => {
    return axiosService.put(`/materials/${materialId}`, material);
};

export const createMaterialApi = (material) => {
    return axiosService.post(`/materials`, material);
};
