import React from "react";
import {Button} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";
import {ChipTipus} from "../../components/unit/ChipTipus";

function dangerouslyHTML(params) {
    const data = params.value?.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "20px"
            }}
            dangerouslySetInnerHTML={{__html: data}}
        />
    );
}

function veureAlWeb(params) {
    const URL = "https://www.musicsperlacobla.cat/compositor";
    return (
        <a
            href={`${URL}/${params.row.id}/${params.row.nomSencer}`}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: "#4782DA",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: "pointer",
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <OpenInNew/>
            </Button>
        </a>
    );
}

const columns = [
    {
        field: "ACTIONS",
        headerName: "",
        width: 90,
        renderCell: veureAlWeb,
    },
    {
        field: "cognom",
        headerName: "Cognoms",
        width: 200,
    },
    {
        field: "nom",
        headerName: "Nom",
        width: 120,
        editable: false,
    },
    {
        field: "tipus",
        headerName: "Tipus",
        width: 100,
        renderCell: (params) => {
            return <ChipTipus label={params.value}/>

        }
    },
    {
        field: "principal",
        headerName: "Principal",
        type: "boolean",
        width: 80,
    },
    {
        field: "esDona",
        headerName: "És dona",
        type: "boolean",
        width: 80,
    },
    {
        field: "biografia",
        headerName: "Biografia",
        width: 300,
        renderCell: dangerouslyHTML,
    },
    {
        field: "notes",
        headerName: "Notes",
        width: 200,
        renderCell: dangerouslyHTML,
    },
    {
        field: "anyNaixament",
        headerName: "Any naix.",
        type: "date",
        width: 90,
    },
    {
        field: "poblacioNaixament",
        headerName: "Pob. naix.",
        width: 110,
    },
    {
        field: "comarcaNaixament",
        headerName: "Comarca naix.",
        width: 130,
    },
    {
        field: "anyMort",
        headerName: "Any mort",
        type: "date",
        width: 90,
    },
    {
        field: "poblacioMort",
        headerName: "Població mort",
        width: 120,
    },
    {
        field: "comarcaMort",
        headerName: "Comarca mort",
        width: 120,
    },
];
export default columns;
