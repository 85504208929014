import { getTitolsService, getTitolService } from "../titols/titolsService";
import { getCompositorsInfoService } from "../compositorsInfo/compositorsInfoService";
import { getTipusService } from "../tipus/tipusService";
import { getInstrumentsService } from '../instruments/instrumentsService';

export const getTitolsCommonService = (_page, search, itemsPerPage) => {
  const promises = [];

  promises.push(
    getCompositorsInfoService(),
    getTitolsService(_page, search, itemsPerPage)
  );

  return Promise.all(promises)
    .then((r) => {
      const compositorsInfo = r[0];
      const titols = r[1];
      return getAllDataFormatterFromApi(compositorsInfo, titols);
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
};

export const getTitolCommonService = (id) => {
  const promises = [];

  promises.push(
    getCompositorsInfoService(),
    getTitolService(id),
    getTipusService()
  );

  return Promise.all(promises)
    .then((r) => {
      const compositorsInfo = r[0];
      const titols = r[1];
      const tipus = r[2];
      return getDataFormatterFromApi(compositorsInfo, titols, tipus);
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
};

export const getTipusCommonService = () => {
  const promises = [];

  promises.push(getTipusService());

  return Promise.all(promises)
    .then((r) => {
      const tipus = r[0];
      return tipusFormatter(tipus);
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });
};

export const getInstrumentsCommonService = () => {
    const promises = [];
  
    promises.push(getInstrumentsService());
  
    return Promise.all(promises)
      .then((r) => {
        const instruments = r[0];
        return instrumentsFormatter(instruments);
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  };

  export const getCompositorsInfoCommonService = () => {
    const promises = [];
  
    promises.push(getCompositorsInfoService());
  
    return Promise.all(promises)
      .then((r) => {
        const compositorsInfo = r[0];
        return compositorsInfoFormatter(compositorsInfo);
      })
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });
  };

// FORMATTERS
const getAllDataFormatterFromApi = (compositorsInfo, titols) => {
  const results = [];

  const count = titols.count;

  titols.rows.forEach((titol) => {
    results.push(getDataFormatterFromApi(compositorsInfo, titol));
  });
  return {
    count,
    results,
  };
};

const getDataFormatterFromApi = (compositorsInfo, titol, tipus) => {
  return {
    ...titol,
    autor: compositorsInfo?.find((c) => c.id === titol.autorId).nomSencer,
    descripcioTipus: tipus?.find((t) => t.type === titol.tipus).descripcio,
  };
};

function tipusFormatter(tipus) {
  let tipusTitol = [];
  tipus?.forEach((item) => {
    tipusTitol.push({
      optionValue: item.descripcio,
    });
  });
  return tipusTitol;
}


function instrumentsFormatter(instruments) {
  let instrumentsTitol = [];
  instruments?.forEach((item) => {
    instrumentsTitol.push({
      optionValue: item.instrument,
    });
  });
  return instrumentsTitol;
}

function compositorsInfoFormatter(compositorsInfo) {
    let compositors = [];
    compositorsInfo?.forEach((item) => {
      compositors.push({
          optionValue: item.id,
          optionItem: item.nomSencer,
      });
    });
    return compositors;
  }
