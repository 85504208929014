import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {uploadCompositorFilesService} from "../../data/services/compositors/compositorsService";

import {Box, Button as MuiButton, TextField as MuiTextField,} from "@mui/material";
import {spacing} from "@mui/system";
import {CloudUpload as MuiCloudUpload} from "@mui/icons-material";

const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const TextField = styled(MuiTextField)(spacing);

function UploadFile({handleChange, image, id, name, alt, peuImatge, peuName}) {
    // const IMG_PATH = "https://www.musicsperlacobla.cat/autors";
    const FILE_UPLOAD = process.env.REACT_APP_FILES_FOLDER_PATH;

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const imageStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "184px",
        height: "315px",
    };

    useEffect(() => {
        setImageUrl(`${FILE_UPLOAD}/${image}`);
    }, [image, FILE_UPLOAD]);

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
            handleUploadFile();
        }
    }, [selectedImage]);

    function handleUploadFile() {
        const formData = new FormData();
        formData.append("file", selectedImage, selectedImage.name);
        if (selectedImage) {
            uploadCompositorFilesService(formData);
        }
    }

    return (
        <div style={imageStyle}>
            {image ? (
                <>
                    <div>
                        {imageUrl && (
                            <Box mt={2} textAlign="center">
                                <img src={imageUrl} alt={alt} height="264px" width="184px"/>
                            </Box>
                        )}
                    </div>
                    <div style={{marginTop: "5px"}}>
                        <input
                            accept="image/*"
                            style={{display: "none"}}
                            id={id}
                            name="file"
                            type="file"
                            onChange={(e) => {
                                setSelectedImage(e.target.files[0]);
                                handleChange({
                                    target: {
                                        name: name,
                                        value: e.target.files[0].name,
                                    },
                                });
                            }}
                        />
                        <label htmlFor={id}>
                            <Button variant="contained" color="primary" component="span">
                                <CloudUpload mr={2}/> Pujar
                            </Button>
                        </label>
                    </div>
                    <TextField
                        style={{marginTop: "30px"}}
                        label="Peu d'imatge"
                        name={peuName}
                        fullWidth
                        type="string"
                        InputLabelProps={{shrink: true}}
                        value={peuImatge}
                        onChange={(e) => {
                            handleChange({
                                target: {
                                    name: peuName,
                                    value: e.target.value,
                                },
                            });
                        }}
                    />
                </>
            ) : (
                <div style={imageStyle}>
                    <input
                        accept="image/*"
                        style={{display: "none"}}
                        id={id}
                        name="file"
                        type="file"
                        onChange={(e) => {
                            setSelectedImage(e.target.files[0]);
                            handleChange({
                                target: {
                                    name: name,
                                    value: e.target.files[0].name,
                                },
                            });
                        }}
                    />
                    <h3>No hi ha cap imatge</h3>
                    <label htmlFor={id}>
                        <Button variant="contained" color="primary" component="span">
                            <CloudUpload mr={2}/> Pujar
                        </Button>
                    </label>
                </div>
            )}
        </div>
    );
}

export default UploadFile;
