import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Snackbar,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";

import {
    createDestacatService,
    getDestacatService,
    updateDestacatService,
} from "../../data/services/destacats/destacatsService";
import CircularIndeterminate from "../../components/unit/CircularProgress";
// import UploadFile from "../../components/unit/UploadFile";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {Alert} from "@mui/lab";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);

const DestacatDetails = () => {
    const navigate = useNavigate();
    const [destacat, setDestacat] = useState({});

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const {id} = useParams();
    const {state} = useLocation();

    const [inputs, setInputs] = useState({
        titol: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Títol",
                value: "",
                onChange: handleChangeInputs
            }
        },
        subtitol: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Subtítol",
                value: "",
                onChange: handleChangeInputs
            }
        },
        ordre: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Ordre",
                value: "",
                onChange: handleChangeInputs
            }
        },
        link: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Enllaç",
                value: "",
                onChange: handleChangeInputs
            }
        },
    })

    useEffect(() => {
        if (id !== "new") fetchData()
    }, [id]);

    const fetchData = () => {
        setIsLoading(true)
        getDestacatService(id)
            .then((destacat) => {
                parseData(destacat)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error obtenint el destacat");
                setSnackbar(true);
                setIsLoading(false)
            })
    }

    const parseData = (destacat) => {
        const newInputs = {...inputs};
        Object.keys(newInputs).forEach((key) => {
            newInputs[key].options.value = destacat[key];
        })
        setInputs(newInputs)
        setDestacat(destacat)
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    // function handleChange(event) {
    //     const value = event.target.value;
    //     const name = event.target.name;
    //     setDestacat({
    //         ...destacat,
    //         [name]: value
    //     })
    // }

    function updateDestacat() {
        const updatedDestacat = destacat;
        Object.keys(inputs).forEach((key) => {
            updatedDestacat[key] = inputs[key].options.value;
        })
        setIsUpdating(true);

        if (id === "new") {
            createDestacatService(updatedDestacat)
                .then((destacat) => {
                    parseData(destacat)
                    setSnackbarSeverity("success");
                    setSnackbarText("Destacat desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                    navigate(`/destacats/${destacat.id}`, {state: {nom: destacat.titol}});
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error creant el destacat");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        } else {
            updateDestacatService(id, updatedDestacat)
                .then((destacat) => {
                    parseData(destacat)
                    setSnackbarSeverity("success");
                    setSnackbarText("Destacat desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error desant el destacat");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        }
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <Button sx={{mb: 5}} variant="outlined" onClick={() => {
                    navigate("/destacats")
                }} style={{display: "flex", gap: 8}}>
                    <ArrowBackIosNewIcon size={20}/>
                    ENRERE
                </Button>
                <Button
                    sx={{mb: 5}}
                    disabled={isUpdating}
                    style={{color: 'white'}}
                    variant="contained"
                    onClick={updateDestacat}
                >
                    <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                        DESAR
                        {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                    </div>
                </Button>
            </div>
            <Typography variant="h3" gutterBottom>
                Detalls de {state?.nom}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                <Link component={NavLink} to="/compositors">
                    Músics per la cobla
                </Link>
                <Link component={NavLink} to="/destacats">
                    Destacats
                </Link>
                <Typography>{state?.nom}</Typography>
            </Breadcrumbs>

            {isLoading ? (
                <CircularIndeterminate/>
            ) : (
                <>

                    <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 8}}>
                                Dades
                            </Typography>
                            <Grid container spacing={8}>
                                {Object.keys(inputs).map((key, index) => {
                                        const input = inputs[key];
                                        return (
                                            <Grid key={index} item md={4} style={{minWidth: 224}}>
                                                <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                            </Grid>
                                        )
                                    }
                                )}
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 15}}>
                                Imatges
                            </Typography>
                            <Grid mt={3} container spacing={12} ml={5} mb={20}>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={destacat.imatge}
                                        id="imatge"
                                        name="imatge"
                                        alt="imatge"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> */}
                    <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                        <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    );
};
export default DestacatDetails;
