import React from "react"
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {parseISO} from "date-fns";
import {DatePicker} from "@mui/lab";
import AutocompleteSelect from "../unit/AutocompleteSelect";

const CustomTextField = ({type, options = {}}) => {

    if (type === CustomTextFieldTypes.BLANK) {
        return <div style={{flex: 1}}/>
    }
    if (type === CustomTextFieldTypes.INPUT) {
        return (
            <TextField
                label={options.label}
                name={options.name}
                fullWidth
                type="string"
                style={options.style}
                value={options.value}
                onChange={options.onChange}
            />
        )
    }

    if (type === CustomTextFieldTypes.YEAR) {
        return <DatePicker
            label={options.label}
            name={options.name}
            fullWidth
            views={["year"]}
            value={parseISO(options.value)}
            onChange={(value) =>
                options.onChange({
                    target: {
                        name: options.name,
                        value: value?.getFullYear().toString(),
                    },
                })
            }
            minDate={new Date("1800")}
            renderInput={(params) => <TextField fullWidth {...params} error={false}/>}
        />
    }
    if (type === CustomTextFieldTypes.AUTOCOMPLETE_SELECT) {
        return (
            <AutocompleteSelect
                label={options.label}
                name={options.name}
                fullWidth
                value={options.value}
                InputLabelProps={{shrink: true}}
                options={options.dropdownOptions}
                onChange={(value) =>
                    options.onChange({
                        target: {
                            name: options.name,
                            value: value
                        },
                    })
                }
            />
        )
    }
    if (type === CustomTextFieldTypes.SELECT) {
        return (
            <FormControl style={{width: '100%'}}>
                <InputLabel id={"select-" + options.name}>{options.label}</InputLabel>
                <Select
                    labelId={"select-" + options.name}
                    name={options.name}
                    fullWidth
                    value={options.value}
                    label={options.label}
                    displayEmpty
                    onChange={options.onChange}
                >
                    {options.dropdownOptions?.map((option) =>
                        <MenuItem value={option.optionValue}>{option.optionItem}</MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }
    if (type === CustomTextFieldTypes.CHECKBOX) {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={options.value}
                        onChange={options.onChange}
                        name={options.name}
                    />
                }
                label={options.label}
            />
        )
    }

    return <></>

}

export const CustomTextFieldTypes = {
    INPUT: "input",
    BLANK: "blank",
    YEAR: "year",
    AUTOCOMPLETE_SELECT: "autocomplete_select",
    SELECT: "select",
    CHECKBOX: "checkbox"
}

export default CustomTextField;
