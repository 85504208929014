export const getInstrumentsFormatterFromApi = (data) => {
    let instruments = [];

    data["instruments"].forEach((item) => {
        instruments.push(getInstrumentFormatterFromApi(item));
    });

    return instruments;
};

export const getInstrumentFormatterFromApi = (item) => {
    return {
        instrument: item.instrument,
    };
};
