import React from "react";
import {Button} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";

function dangerouslyHTML(params) {
    const data = params.value?.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "20px"
            }}
            dangerouslySetInnerHTML={{__html: data}}
        />
    );
}

function veureAlWeb(params) {
    const URL = "https://www.musicsperlacobla.cat/blog/?p=";
    return (
        <a
            href={`${URL}${params.row.id}`}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: "#4782DA",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: "pointer",
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <OpenInNew/>
            </Button>
        </a>
    );
}

const columns = [
    {
        field: "ACTIONS",
        headerName: "",
        width: 90,
        renderCell: veureAlWeb,
    },
    {
        field: "postTitol",
        headerName: "Títol",
        width: 500,
        renderCell: dangerouslyHTML,
    },
    {
        field: "data",
        headerName: "Data",
        width: 200,
    },
];
export default columns;
