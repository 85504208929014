import React from "react";
import {Button} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";

function veureAlWeb(params) {
    const URL = "https://www.musicsperlacobla.cat/cd.php?id_cd=";
    return (
        <a
            href={`${URL}${params.row.id}`}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: "#4782DA",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: "pointer",
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <OpenInNew/>
            </Button>
        </a>
    );
}

const columns = [
    {
        field: "ACTIONS",
        headerName: "",
        width: 90,
        renderCell: veureAlWeb,
    },
    {
        field: "referencia",
        headerName: "Referència",
        width: 150,
    },
    {
        field: "nom",
        headerName: "Nom",
        width: 300,
    },
    {
        field: "data",
        headerName: "Any",
        width: 90,
    },
    {
        field: "ordre",
        headerName: "Ordre",
        width: 90,
    }
];
export default columns;
