import React, {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';

const DataGridMPC = ({
                         rows, columns, onRowClick, loading, onPageChange, rowCount, onPageSizeChange,
                         checkboxSelection, density, onSelectionModelChange = () => null
                     }) => {

    const [_pageSize, _setPageSize] = useState(50);

    const _onPageSizeChange = (pageSize) => {
        _setPageSize(pageSize);
        onPageSizeChange(pageSize);
    }

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={_pageSize}
            onPageChange={onPageChange}
            rowsPerPageOptions={[25, 50, 75, 100]}
            disableSelectionOnClick
            onSelectionModelChange={onSelectionModelChange}
            checkboxSelection={checkboxSelection}
            onPageSizeChange={_onPageSizeChange}
            onRowClick={onRowClick}
            paginationMode={"server"}
            rowCount={rowCount}
            loading={loading}
            componentsProps={{
                pagination: {
                    labelRowsPerPage: "Files per pàgina",
                    labelDisplayedRows: ({from, to, count}) => {
                        return '' + from + '-' + to + ' de ' + count
                    }
                }
            }}
            density={density}
        />
    );
}
export default DataGridMPC;
