const columns = [
    {
        field: "nom",
        headerName: "Nom",
        width: 200,
    },
    {
        field: "rol",
        headerName: "Rol",
        width: 120,
        editable: false,
    },
    {
        field: "activitats",
        headerName: "Activitats",
        width: 250,
    }
    
];
export default columns;
