import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {spacing} from "@mui/system";
import {Box as MuiBox, Drawer as MuiDrawer, ListItemButton,} from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import mpc_logo from "./../../../assets/logo.png";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
    border-right: 0;
    margin-left: 258px;

    > div {
        border-right: 0;
        width: 258px;
    }
`;

const Brand = styled(ListItemButton)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;
    padding-left: ${(props) => props.theme.spacing(6)};
    padding-right: ${(props) => props.theme.spacing(6)};
    justify-content: center;
    cursor: pointer;
    flex-grow: 0;

    ${(props) => props.theme.breakpoints.up("sm")} {
        min-height: 64px;
    }

    &:hover {
        background-color: ${(props) => props.theme.sidebar.header.background};
    }
`;

const Logo = styled.img`
    width: 90px;
    height: 90px;
    margin-top: 20px;
`;

const Sidebar = ({items, showFooter = true, ...rest}) => {
    return (
        <Drawer variant="permanent" {...rest} style={{zIndex: 1}}>
            <Brand
                component={NavLink}
                to="/"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#233044",
                    color: "white",
                }}
            >
                <Box ml={1}>
                    <Logo src={`${mpc_logo}`} alt="mcp_logo"/>
                </Box>
            </Brand>
            <SidebarNav items={items}/>
            {!!showFooter && <Footer/>}
        </Drawer>
    );
};

export default Sidebar;
