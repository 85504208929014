import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getTitolsApi = (page = 1, search, itemsPerPage) => {
    let url = "/titols?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteTitolApi = (titolCodi) => {
    return axiosService.delete(`/titols/${titolCodi}`);
};

export const getTitolApi = (titolCodi) => {
    return axiosService.get(`/titols/${titolCodi}`);
};

export const updateTitolApi = (titolCodi, titol) => {
    return axiosService.put(`/titols/${titolCodi}`, titol);
};

export const createTitolApi = (titol) => {
    return axiosService.post(`/titols`, titol);
};

export const uploadTitolFilesApi = (file, fileName) => {
    return axiosService.post("/data/upload", file, fileName, {headers: headers});
};
