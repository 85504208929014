import React from "react";
import {useRoutes} from "react-router-dom";
import {ThemeProvider} from "styled-components/macro";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from '@mui/lab/AdapterDateFns';

import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import Routes from "./routes";

function App() {
    const routes = useRoutes(Routes);
    const {theme} = useTheme();

    return (
        <>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <MuiThemeProvider theme={createTheme(theme)}>
                    <ThemeProvider theme={createTheme(theme)}>
                        {routes}
                    </ThemeProvider>
                </MuiThemeProvider>
            </LocalizationProvider>
        </>
    );
}

export default App;
