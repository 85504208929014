export const getEnregistramentsFormatterFromApi = (data) => {
    let enregistrament = [];

    data["hydra:member"].forEach((item) => {
        enregistrament.push(getEnregistramentFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        enregistrament
    };
};

export const getEnregistramentFormatterFromApi = (item) => {
    return {
        id: item.idCd,
        ordre: item.ordre,
        referencia: item.referencia,
        nom: item.nom,
        monografic: item.mono,
        data: item.data,
        cobla: item.cobla,
        director: item.direc,
        col: item.col,
        portada: item.portada,
        mini: item.mini,
        contra: item.contra,
        galeta: item.galeta,
        comentaris: item.comentaris,
        credits: item.credits,
        llibret: item.llibret,
        inici: item.inici,
        doble: item.doble,
        segonCd: item.segonCd,
        altreCd: item.altreCd,
        linkSpotify: item.linkSpotify
    };
};

export const putEnregistramentFormatterToApi = (item) => {
    return {
        idCd: item.id,
        ordre: parseInt(item.ordre),
        referencia: item.referencia,
        nom: item.nom,
        mono: item.monografic,
        data: item.data,
        cobla: item.cobla,
        direc: item.director,
        col: parseInt(item.col),
        portada: item.portada,
        mini: item.mini,
        contra: item.contra,
        galeta: item.galeta,
        comentaris: item.comentaris,
        credits: item.credits,
        llibret: item.llibret,
        inici: item.inici,
        doble: item.doble,
        segonCd: item.segonCd,
        altreCd: item.altreCd,
        linkSpotify: item.linkSpotify
    };
};


export const postEnregistramentFormatterToApi = (item) => {
    return {
        idCd: item.id,
        ordre: parseInt(item.ordre),
        referencia: item.referencia,
        nom: item.nom,
        mono: item.monografic,
        data: item.data,
        cobla: item.cobla,
        direc: item.director,
        col: parseInt(item.col),
        portada: item.portada,
        mini: item.mini,
        contra: item.contra,
        galeta: item.galeta,
        comentaris: item.comentaris,
        credits: item.credits,
        llibret: item.llibret,
        inici: item.inici,
        doble: item.doble,
        segonCd: item.segonCd,
        altreCd: item.altreCd,
        linkSpotify: item.linkSpotify
    };
};
