import {
    getCollaboradorsFormatterFromApi,
    getCollaboradorFormatterFromApi,
    putCollaboradorFormatterToApi,
    postCollaboradorFormatterToApi
} from "./../../formatters/collaboradorsFormatter";
import {createCollaboradorApi, deleteCollaboradorApi, getCollaboradorApi, getCollaboradorsApi, updateCollaboradorApi} from "../../api/data/collaboradorsApi";


export const getCollaboradorsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getCollaboradorsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCollaboradorsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all collaboradors");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getCollaboradorService = (collaboradorId) => {
    return new Promise((resolve, reject) => {
        getCollaboradorApi(collaboradorId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCollaboradorFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single collaborador");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateCollaboradorService = (collaboradorId, collaborador) => {
    return new Promise((resolve, reject) => {
        updateCollaboradorApi(collaboradorId, putCollaboradorFormatterToApi(collaborador))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCollaboradorFormatterFromApi(response));
                } else {
                    throw new Error("Error editing collaborador");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createCollaboradorService = (collaborador) => {
    return new Promise((resolve, reject) => {
        createCollaboradorApi(postCollaboradorFormatterToApi(collaborador))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getCollaboradorFormatterFromApi(response));
                } else {
                    throw new Error("Error creating collaborador");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteCollaboradorsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteCollaboradorApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting collaborador " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
