const columns = [
    {
        field: "codi",
        headerName: "Nº de registre",
        width: 200,
    },
    {
        field: "tipus",
        headerName: "Tipus",
        width: 90,
    },
    {
        field: "pdf",
        headerName: "PDF",
        type: "boolean",
        width: 90,
    },
    {
        field: "descripcio",
        headerName: "Descripció",
        width: 200,
    },
    {
        field: "notes",
        headerName: "Notes",
        width: 200,
    }
];
export default columns;
