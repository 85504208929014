import {
    getBlogsFormatterFromApi,
    getBlogFormatterFromApi,
    putBlogFormatterToApi,
    postBlogFormatterToApi
} from "../../formatters/blogsFormatter";
import {createBlogApi, deleteBlogApi, getBlogApi, getBlogsApi, updateBlogApi, uploadBlogFilesApi} from "../../api/data/blogsApi";


export const getBlogsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getBlogsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getBlogsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all blogs");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getBlogService = (blogId) => {
    return new Promise((resolve, reject) => {
        getBlogApi(blogId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getBlogFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single blog");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateBlogService = (blogId, blog) => {
    return new Promise((resolve, reject) => {
        updateBlogApi(blogId, putBlogFormatterToApi(blog))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getBlogFormatterFromApi(response));
                } else {
                    throw new Error("Error editing blog");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createBlogService = (blog) => {
    return new Promise((resolve, reject) => {
        createBlogApi(postBlogFormatterToApi(blog))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getBlogFormatterFromApi(response));
                } else {
                    throw new Error("Error creating blog");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const uploadBlogFilesService = (file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadBlogFilesApi(file, fileName)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(response);
                } else {
                    throw new Error("Uploading file error");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteBlogsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteBlogApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting blog " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
