import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    Snackbar,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";

import {
    createCompositorService,
    getCompositorService,
    updateCompositorService,
} from "../../data/services/compositors/compositorsService";
import poblacionsJson from "./../../poblacions.json";
import comarquesJson from "./../../comarques.json";
import tipusCompositorJson from "./../../tipusCompositor.json";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import UploadFile from "../../components/unit/UploadFile";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {Alert} from "@mui/lab";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const getPoblacionsOptions = () => {
    let poblacionsNaixament = [];
    poblacionsJson.forEach((item) => {
        poblacionsNaixament.push({
            optionValue: item.poblacio,
        });
    });
    return getUniqueListBy(poblacionsNaixament, 'optionValue');
};

const poblacions = getPoblacionsOptions();


const getComarquesOptions = () => {
    let comarquesNaixament = [];
    comarquesJson.forEach((item) => {
        comarquesNaixament.push({
            optionValue: item.nom,
        });
    });
    return getUniqueListBy(comarquesNaixament, 'optionValue');
};
const comarques = getComarquesOptions();

const getTipusCompositor = () => {
    let tipusCompositor = [];
    tipusCompositorJson?.forEach((item) => {
        tipusCompositor.push({
            optionValue: item.tipus,
            optionItem: item.tipus,
        });
    });
    return tipusCompositor;
};

const compositorsTipus = getTipusCompositor();

const CompositorDetails = () => {
    const navigate = useNavigate();
    const [compositor, setCompositor] = useState({});

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const {id} = useParams();
    const {state} = useLocation();

    const [inputs, setInputs] = useState({
        nom: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nom",
                value: "",
                onChange: handleChangeInputs
            }
        },
        cognom: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Cognoms",
                value: "",
                onChange: handleChangeInputs
            }
        },
        nomSencer: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nom sencer",
                value: "",
                onChange: handleChangeInputs
            }
        },
        anyNaixament: {
            type: CustomTextFieldTypes.YEAR,
            options: {
                label: "Any de naixement",
                value: "",
                onChange: handleChangeInputs
            }
        },
        comarcaNaixament: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Comarca de naixement",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: comarques
            }
        },
        poblacioNaixament: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Població de naixement",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: poblacions
            }
        },
        anyMort: {
            type: CustomTextFieldTypes.YEAR,
            options: {
                label: "Any de mort",
                value: "",
                onChange: handleChangeInputs
            }
        },
        comarcaMort: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Comarca de mort",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: comarques
            }
        },
        poblacioMort: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Població de mort",
                value: "",
                onChange: handleChangeInputs,
                dropdownOptions: poblacions
            }
        },
        tipus: {
            type: CustomTextFieldTypes.SELECT,
            options: {
                label: "Tipus de compositor",
                value: "normal",
                onChange: handleChangeInputs,
                dropdownOptions: compositorsTipus
            }
        },
        esDona: {
            type: CustomTextFieldTypes.CHECKBOX,
            options: {
                label: "És dona?",
                value: false,
                onChange: handleChecked
            }
        },
    })

    useEffect(() => {
        if (id !== "new") fetchData()
    }, [id]);

    const fetchData = () => {
        setIsLoading(true)
        getCompositorService(id)
            .then((compositor) => {
                parseData(compositor)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error obtenint el compositor");
                setSnackbar(true);
                setIsLoading(false)
            })
    }

    const parseData = (compositor) => {
        const newInputs = {...inputs};
        Object.keys(newInputs).forEach((key) => {
            newInputs[key].options.value = compositor[key];
        })
        setInputs(newInputs)
        setCompositor(compositor)
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        setCompositor({
            ...compositor,
            [name]: value
        })
    }

    function handleChecked(event) {
        const value = event.target.checked;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function updateCompositor() {
        const updatedCompositor = compositor;
        Object.keys(inputs).forEach((key) => {
            updatedCompositor[key] = inputs[key].options.value;
        })
        setIsUpdating(true);

        if (id === "new") {
            createCompositorService(updatedCompositor)
                .then((compositor) => {
                    parseData(compositor)
                    setSnackbarSeverity("success");
                    setSnackbarText("Compositor desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                    navigate(`/compositors/${compositor.id}`, {state: {nom: compositor.nomSencer}});
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error creant el compositor");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        } else {
            updateCompositorService(id, updatedCompositor)
                .then((compositor) => {
                    parseData(compositor)
                    setSnackbarSeverity("success");
                    setSnackbarText("Compositor desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error desant el compositor");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        }
    }


    return (
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <Button sx={{mb: 5}} variant="outlined" onClick={() => {
                    navigate("/compositors")
                }} style={{display: "flex", gap: 8}}>
                    <ArrowBackIosNewIcon size={20}/>
                    ENRERE
                </Button>
                <Button
                    sx={{mb: 5}}
                    disabled={isUpdating}
                    style={{color: 'white'}}
                    variant="contained"
                    onClick={updateCompositor}
                >
                    <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                        DESAR
                        {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                    </div>
                </Button>
            </div>
            <Typography variant="h3" gutterBottom>
                Detalls de {state?.nom}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                <Link component={NavLink} to="/compositors">
                    Músics per la cobla
                </Link>
                <Link component={NavLink} to="/compositors">
                    Compositors
                </Link>
                <Typography>{state?.nom}</Typography>
            </Breadcrumbs>

            {isLoading ? (
                <CircularIndeterminate/>
            ) : (
                <>

                    <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 8}}>
                                Dades
                            </Typography>
                            <Grid container spacing={8}>
                                {Object.keys(inputs).map((key, index) => {
                                        const input = inputs[key];
                                        return (
                                            <Grid key={index} item md={4} style={{minWidth: 224}}>
                                                <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                            </Grid>
                                        )
                                    }
                                )}
                            </Grid>
                        </CardContent>
                    </Card>


                    <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Biografia"
                            value={compositor?.biografia}
                            handleChange={handleChange}
                            name="biografia"
                        />
                    </Card>
                    <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Altres títols"
                            value={compositor?.altres}
                            handleChange={handleChange}
                            name="altres"
                        />
                    </Card>
                    <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Notes"
                            value={compositor?.notes}
                            handleChange={handleChange}
                            name="notes"
                        />
                    </Card>

                    <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 15}}>
                                Imatges
                            </Typography>
                            <Grid mt={3} container spacing={12} ml={5} mb={20}>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={compositor.img1}
                                        id="image1"
                                        name="img1"
                                        alt="image 1"
                                        peuName="peuImg1"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={compositor.img2}
                                        peuImatge={compositor.peuImg2}
                                        id="image2"
                                        name="img2"
                                        alt="image 2"
                                        peuName="peuImg2"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={compositor.img3}
                                        id="image3"
                                        name="img3"
                                        alt="image 3"
                                        peuName="peuImg3"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                        <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    );
};
export default CompositorDetails;
