export const getTitolsFormatterFromApi = (data) => {
    let rows = [];

    data["hydra:member"].forEach((item) => {
        rows.push(getTitolFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        rows
    };
};

export const getTitolFormatterFromApi = (item) => {
    //TODO afegir nom de l'autor a la crida de la API
    return {
        id: item.codi,
        titol: item.titol,
        autorId: item.autorId,
        autor: item.autorNom,
        tipus: item.tipus,
        data: item.data,
        instrument: item.instrument,
        dacomp: item.dacomp, //
        enreg: item.enreg,
        partitu: item.partitu,
        partice: item.partice,
        pdf1: item.pdf1,
        pdf2: item.pdf2,
        pdf3: item.pdf3,
        notes1: item.notes1,
        notes2: item.notes2,
        mp3: item.mp3,
        uriSpotify: item.uriSpotify,
        notes3: item.notes3,
        notes4: item.notes4,
        arranjadorId: item.arranjadorId,
    };
};

export const putTitolFormatterToApi = (item) => {
    return {
        codi: item.id,
        titol: item.titol,
        autor: item.autorNom,
        tipus: item.tipus,
        data: item.data,
        instrument: item.instrument,
        dacomp: item.dacomp,
        enreg: item.enreg,
        partitu: item.partitu,
        partice: item.partice,
        pdf1: item.pdf1,
        pdf2: item.pdf2,
        pdf3: item.pdf3,
        notes1: item.notes1,
        notes2: item.notes2,
        mp3: item.mp3,
        uriSpotify: item.uriSpotify,
        notes3: item.notes3,
        notes4: item.notes4,
        arranjadorId: item.arranjadorId,
    };
};


export const postTitolFormatterToApi = (item) => {
    //TODO revisar que no es passin NULL (ni valor ni "NULL") també al PUT
    return {
        titol: item.titol,
        autor: item.autorNom,
        tipus: item.tipus,
        data: item.data,
        instrument: item.instrument,
        dacomp: item.dacomp,
        enreg: item.enreg,
        partitu: item.partitu,
        partice: item.partice,
        pdf1: item.pdf1,
        pdf2: item.pdf2,
        pdf3: item.pdf3,
        notes1: item.notes1,
        notes2: item.notes2,
        mp3: item.mp3,
        uriSpotify: item.uriSpotify,
        notes3: item.notes3,
        notes4: item.notes4,
        arranjadorId: item.arranjadorId,
    };
};
