export const getMaterialsDescripcioFormatterFromApi = (data) => {
    let materialDescripcio = [];

    data.forEach((item) => {
        materialDescripcio.push(getMaterialDescripcioFormatterFromApi(item));
    });

    return materialDescripcio;
};

export const getMaterialDescripcioFormatterFromApi = (item) => {
    return {
        id: item.id_desc,
        descripcio: item.descripcio,
    };
};

export const putMaterialDescripcioFormatterToApi = (item) => {
    return {
        id_desc: item.id,
        descripcio: item.descripcio,
    };
};


export const postMaterialDescripcioFormatterToApi = (item) => {
    return {
        id_desc: item.id,
        descripcio: item.descripcio,
    };
};
