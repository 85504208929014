import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getEnregistramentsApi = (page = 1, search, itemsPerPage) => {
    let url = "/enregistraments?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteEnregistramentApi = (enregistramentId) => {
    return axiosService.delete(`/enregistraments/${enregistramentId}`);
};

export const getEnregistramentApi = (enregistramentId) => {
    return axiosService.get(`/enregistraments/${enregistramentId}`);
};

export const updateEnregistramentApi = (enregistramentId, enregistrament) => {
    return axiosService.put(`/enregistraments/${enregistramentId}`, enregistrament);
};

export const createEnregistramentApi = (enregistrament) => {
    return axiosService.post(`/enregistraments`, enregistrament);
};

export const uploadEnregistramentFilesApi = (file, fileName) => {
    return axiosService.post("/data/upload", file, fileName, {headers: headers});
};
