import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getCompositorsApi = (page = 1, search, itemsPerPage) => {
    let url = "/compositors?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const getCompositorApi = (autorId) => {
    return axiosService.get(`/compositors/${autorId}`);
};

export const updateCompositorApi = (autorId, compositor) => {
    return axiosService.put(`/compositors/${autorId}`, compositor);
};

export const createCompositorApi = (compositor) => {
    return axiosService.post(`/compositors`, compositor);
};

export const uploadCompositorFilesApi = (file, fileName) => {
    return axiosService.post("/compositors/upload", file, fileName, {headers: headers});
};
