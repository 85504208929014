const columns = [
    {
        field: "titol",
        headerName: "Títol",
        width: 400,
    },
    {
        field: "subtitol",
        headerName: "Subtítol",
        width: 250,
    },
    {
        field: "ordre",
        headerName: "Ordre",
        width: 90,
    },
];
export default columns;
