import {
    getMaterialsFormatterFromApi,
    getMaterialFormatterFromApi,
    putMaterialFormatterToApi,
    postMaterialFormatterToApi
} from "./../../formatters/materialsFormatter";
import {createMaterialApi, deleteMaterialApi, getMaterialApi, getMaterialsApi, updateMaterialApi} from "../../api/data/materialsApi";


export const getMaterialsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getMaterialsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getMaterialsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all materials");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getMaterialService = (materialId) => {
    return new Promise((resolve, reject) => {
        getMaterialApi(materialId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getMaterialFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single material");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateMaterialService = (materialId, material) => {
    return new Promise((resolve, reject) => {
        updateMaterialApi(materialId, putMaterialFormatterToApi(material))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getMaterialFormatterFromApi(response));
                } else {
                    throw new Error("Error editing material");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createMaterialService = (material) => {
    return new Promise((resolve, reject) => {
        createMaterialApi(postMaterialFormatterToApi(material))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getMaterialFormatterFromApi(response));
                } else {
                    throw new Error("Error creating material");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteMaterialsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteMaterialApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting material " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
