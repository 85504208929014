import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import Searchbar from "../../components/Layout/Searchbar";
import SmallButton from "../../components/unit/SmallButton";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import DataGridMPC from "../../components/unit/DataGridMPC";
import columns from "./DestacatsColumns";
import {
  deleteDestacatsService,
  getDestacatsService,
} from "../../data/services/destacats/destacatsService";
import { Alert } from "@mui/lab";

const Destacats = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [_page, _setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarText, setSnackbarText] = useState("");

  const [idsToDelete, setIdsToDelete] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  function onRowClick(params) {
    navigate(`${params.row.id}`, { state: { nom: params.row.titol } });
  }

  function onCreateClick() {
    navigate(`new`, { state: { nom: "Nou destacat" } });
  }

  function onDeleteClick() {
    const res = window.confirm(
      "Segur que vols esborrar aquests destacats?"
    );
    if (!res) return;
    setIsUpdating(true);
    deleteDestacatsService(idsToDelete)
      .then(() => {
        setSnackbarSeverity("success");
        setSnackbarText("Destacat esborrats correctament");
        setSnackbar(true);
        setLoading(true);
        fetchData();
      })
      .catch(() => {
        setSnackbarSeverity("error");
        setSnackbarText("Hi ha hagut un error esborrant els destacats");
        setSnackbar(true);
        setIsUpdating(false);
      });
  }

  const fetchData = () => {
    getDestacatsService(_page, search, itemsPerPage).then((res) => {
      setRows(res.rows);
      setCount(res.count);
      setLoading(false);
      setIsUpdating(false);
    });
  };

  useEffect(() => {
    setRows([]);
    setLoading(true);
    fetchData();
  }, [_page, search, itemsPerPage]);

  const onPageChange = (page) => {
    _setPage(page + 1);
  };

  const onSearchChange = (str) => {
    setSearch(str.target.value);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Grid justifyContent="space-between" container spacing={6} py={3}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
          Destacats
          </Typography>
          <Grid>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/compositors">
                Músics per la cobla
              </Link>
              <Typography>Destacats</Typography>
            </Breadcrumbs>
            <Searchbar placeholder="Buscar destacats" onChange={onSearchChange} />
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: "81px" }}>
          {idsToDelete.length > 0 && (
            <Button
              onClick={onDeleteClick}
              variant="contained"
              color="error"
              disabled={isUpdating}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                ESBORRAR
                {isUpdating && (
                  <CircularProgress size={18} style={{ color: "white" }} />
                )}
              </div>
            </Button>
          )}
          <SmallButton size="small" mr={2}>
            <FilterListIcon />
          </SmallButton>
          <Button onClick={onCreateClick} variant="contained" color="secondary">
            CREAR
          </Button>
        </Grid>
      </Grid>

      <Paper style={{ display: "flex", height: "100%", flex: 1 }}>
        <DataGridMPC
          rowCount={count}
          checkboxSelection
          onSelectionModelChange={(data) => setIdsToDelete(data)}
          onPageSizeChange={(pageSize) => {
            setItemsPerPage(pageSize);
          }}
          onPageChange={onPageChange}
          columns={columns}
          rows={rows}
          onRowClick={onRowClick}
          loading={loading}
        />
      </Paper>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
      >
        <Alert
          onClose={() => setSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Destacats;
