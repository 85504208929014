export const getCompositorsFormatterFromApi = (data) => {
    let compositors = [];

    data["hydra:member"].forEach((item) => {
        compositors.push(getCompositorFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        compositors
    };
};

export const getCompositorFormatterFromApi = (item) => {
    return {
        id: item.autorId,
        nom: item.autorNom,
        cognom: item.autorCog,
        nomSencer: item.autorForm,
        biografia: item.bioCat,
        img1: item.ima1,
        img2: item.ima2,
        peuImg2: item.peuIma2,
        img3: item.img3,
        peuImg3: item.peuIma3,
        principal: item.principal,
        tipus: item.tipus,
        notes: item.notes,
        altres: item.altres,
        anyNaixament: item.anyNaix,
        poblacioNaixament: item.poblaNaix,
        comarcaNaixament: item.comarcaNaix,
        anyMort: item.anyMort,
        poblacioMort: item.poblaMort,
        comarcaMort: item.comarcaMort,
        esDona: item.esDona,
    };
};

export const putCompositorFormatterToApi = (item) => {
    return {
        autorId: item.id,
        autorNom: item.nom,
        autorCog: item.cognom,
        autorForm: item.nomSencer,
        bioCat: item.biografia,
        ima1: item.img1,
        ima2: item.img2,
        peuIma2: item.peuImg2,
        ima3: item.img3,
        peuIma3: item.peuImg3,
        principal: item.principal,
        tipus: item.tipus,
        notes: item.notes,
        altres: item.altres,
        anyNaix: item.anyNaixament,
        poblaNaix: item.poblacioNaixament,
        comarcaNaix: item.comarcaNaixament,
        anyMort: item.anyMort,
        poblaMort: item.poblacioMort,
        comarcaMort: item.comarcaMort,
        esDona: item.esDona,
    };
};


export const postCompositorFormatterToApi = (item) => {
    //TODO revisar que no es passin NULL (ni valor ni "NULL") també al PUT
    return {
        autorNom: item.nom,
        autorCog: item.cognom,
        autorForm: item.nomSencer,
        bioCat: item.biografia,
        ima1: item.img1,
        ima2: item.img2,
        peuIma2: item.peuImg2,
        ima3: item.img3,
        peuIma3: item.peuImg3,
        principal: item.principal,
        tipus: item.tipus,
        notes: item.notes,
        altres: item.altres,
        anyNaix: item.anyNaixament,
        poblaNaix: item.poblacioNaixament,
        comarcaNaix: item.comarcaNaixament,
        anyMort: item.anyMort,
        poblaMort: item.poblacioMort,
        comarcaMort: item.comarcaMort,
        esDona: item.esDona,
    };
};
