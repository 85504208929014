import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import styled from "styled-components/macro";

import {spacing} from "@mui/system";
import {Breadcrumbs as MuiBreadcrumbs, Button, Grid, Link, Paper, Typography as MuiTypography,} from "@mui/material";

import DataGridMPC from "../../components/unit/DataGridMPC";
import {getCompositorsService} from "../../data/services/compositors/compositorsService";
import columns from "./CompositorsColumns";
import Searchbar from "../../components/Layout/Searchbar";
import {FilterList as FilterListIcon} from "@mui/icons-material";
import SmallButton from "../../components/unit/SmallButton";

const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Compositors = () => {
    const [compositors, setCompositors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [_page, _setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const navigate = useNavigate();


    function onRowClick(params) {
        navigate(`${params.row.id}`, {state: {nom: params.row.nomSencer}});
    }

    function onCreateClick() {
        navigate(`new`, {state: {nom: "Nou compositor"}});
    }

    useEffect(() => {
        setLoading(true)
        setCompositors([])
        getCompositorsService(_page, search, itemsPerPage)
            .then((res) => {
                setCompositors(res.compositors)
                setCount(res.count);
                setLoading(false)
            });
    }, [_page, search, itemsPerPage]);

    const onPageChange = (page) => {
        _setPage(page + 1)
    }

    const onSearchChange = (str) => {
        setSearch(str.target.value);
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: "column"}}>
            <Grid justifyContent="space-between" container spacing={6} py={3}>
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        Compositors
                    </Typography>
                    <Grid>
                        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                            <Link component={NavLink} to="/compositors">
                                Músics per la cobla
                            </Link>
                            <Typography>Compositors</Typography>
                        </Breadcrumbs>
                        <Searchbar placeholder="Buscar compositors" onChange={onSearchChange}/>
                    </Grid>
                </Grid>
                <Grid item style={{marginTop: "81px"}}>
                    <SmallButton size="small" mr={2}>
                        <FilterListIcon/>
                    </SmallButton>
                    <Button
                        onClick={onCreateClick}
                        variant="contained"
                        color="secondary"
                    >
                        CREAR
                    </Button>
                </Grid>
            </Grid>

            <Paper style={{display: 'flex', height: '100%', flex: 1}}>
                <DataGridMPC
                    rowCount={count}
                    onPageSizeChange={(pageSize) => {
                        setItemsPerPage(pageSize)
                    }}
                    onPageChange={onPageChange}
                    columns={columns}
                    rows={compositors}
                    onRowClick={onRowClick}
                    loading={loading}
                />
            </Paper>
        </div>
    );
};
export default Compositors;
