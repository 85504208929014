import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomTextField, {
  CustomTextFieldTypes,
} from "../../components/composed/CustomTextField";
import {
  createMaterialService,
  updateMaterialService,
} from "../../data/services/materials/materialsService";
import { getMaterialCommonService, getMaterialDescripcioCommonService } from "../../data/services/common/materialsCommonService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import { Alert } from "@mui/lab";
import materialsJson from "./../../materials.json";

const getTipus = () => {
  let tipusMaterial = [];
  materialsJson?.forEach((item) => {
    tipusMaterial.push({
      optionValue: item.tipus,
    });
  });
  return tipusMaterial;
};

const tipus = getTipus();

const MaterialDetails = () => {
  const navigate = useNavigate();
  const [material, setMaterial] = useState({});
  const [descripcioMaterial, setDescripcioMaterial] = useState([]);
//   console.log("descripcioMaterial", descripcioMaterial);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarText, setSnackbarText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { id } = useParams();
  const { state } = useLocation();

  const [inputs, setInputs] = useState({
    codi: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Nº de registre",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
    referencia: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Referència",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    tipus: {
      type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
      options: {
        label: "Tipus",
        value: "",
        onChange: handleChangeInputs,
        dropdownOptions: tipus,
      },
    },
    idDesc: {
      type: CustomTextFieldTypes.SELECT,
      options: {
        label: "Descripció",
        value: material.descripcio,
        // dropdownOptions: descripcioMaterial,
        dropdownOptions: [
            {optionItem: "Manuscrit autògraf", optionValue: 1 }, 
            {optionItem: "Manuscrit", optionValue: 2}, 
            {optionItem: "Reproducció de manuscrit autògraf", optionValue: 3},
            {optionItem: "Reproducció de manuscrit", optionValue: 4}, 
            {optionItem: "Edició informàtica", optionValue: 5}, 
            {optionItem: "Edició tipogràfica", optionValue: 6}, 
            {optionItem: "Reproducció de l'edició tipogràfica", optionValue: 7}, 
            {optionItem: "Altres", optionValue: 8}, 
            {optionItem: "PDF", optionValue: 9}, 
            {optionItem: "Reproducció edició informàtica", optionValue: 10}, 
        ],
        onChange: handleChangeInputs,
      },
    },
  });

  useEffect(() => {
    if (id !== "new") fetchData();
  }, [id]);

  const fetchData = () => {
    setIsLoading(true);
    getMaterialCommonService(id)
      .then((material) => {
        parseData(material);
        setIsLoading(false);
      })
      .catch(() => {
        setSnackbarSeverity("error");
        setSnackbarText("Hi ha hagut un error obtenint el material");
        setSnackbar(true);
        setIsLoading(false);
      });
    getMaterialDescripcioCommonService().then(res => setDescripcioMaterial(res));
  };

  const parseData = (material) => {
    const newInputs = { ...inputs };
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].options.value = material[key];
    });
    setInputs(newInputs);
    setMaterial(material);
  };

  function handleChangeInputs(event) {
    const value = event.target.value;
    const name = event.target.name;
    if (!inputs[name]) return;
    const newInputs = { ...inputs };
    newInputs[name].options.value = value;
    setInputs(newInputs);
  }

  function handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setMaterial({
        ...material,
        [name]: value
    })
}

  function updateMaterial() {
    const updatedMaterial = material;
    Object.keys(inputs).forEach((key) => {
      updatedMaterial[key] = inputs[key].options.value;
    });
    setIsUpdating(true);

    if (id === "new") {
      createMaterialService(updatedMaterial)
        .then((material) => {
          parseData(material);
          setSnackbarSeverity("success");
          setSnackbarText("material desat correctament");
          setSnackbar(true);
          setIsUpdating(false);
          navigate(`${state.path}/${material.id}`, {
            state: { nom: material.codi },
          });
        })
        .catch(() => {
          setSnackbarSeverity("error");
          setSnackbarText("Hi ha hagut un error creant el material");
          setSnackbar(true);
          setIsUpdating(false);
        });
    } else {
      updateMaterialService(id, updatedMaterial)
        .then((material) => {
          parseData(material);
          setSnackbarSeverity("success");
          setSnackbarText("material desat correctament");
          setSnackbar(true);
          setIsUpdating(false);
        })
        .catch(() => {
          setSnackbarSeverity("error");
          setSnackbarText("Hi ha hagut un error desant el material");
          setSnackbar(true);
          setIsUpdating(false);
        });
    }
  }

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ mb: 5 }}
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
            style={{ display: "flex", gap: 8 }}
          >
            <ArrowBackIosNewIcon size={20} />
            ENRERE
          </Button>
          <Button
            sx={{ mb: 5 }}
            disabled={isUpdating}
            style={{ color: "white" }}
            variant="contained"
            onClick={updateMaterial}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              DESAR
              {isUpdating && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </div>
          </Button>
        </div>
        <Typography variant="h3" gutterBottom>
          Detalls de {state?.nom}
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
          <Link component={NavLink} to="/compositors">
            Músics per la cobla
          </Link>
          <Link component={NavLink} to={-1}>
            Materials
          </Link>
          <Typography>{state?.nom}</Typography>
        </Breadcrumbs>

        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <Card sx={{ mb: 5 }}>
              <CardContent>
                <Typography variant="h6" style={{ marginBottom: 8 }}>
                  Dades
                </Typography>
                <Grid container spacing={8}>
                  {Object.keys(inputs).map((key, index) => {
                    const input = inputs[key];
                    return (
                      <Grid key={index} item md={4} style={{ minWidth: 224 }}>
                        <CustomTextField
                          type={input.type}
                          options={{ ...input.options, name: key }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Notes"
                            value={material?.notes}
                            handleChange={handleChange}
                            name="notes"
                        />
                    </Card>

            <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={() => setSnackbar(false)}
            >
              <Alert
                onClose={() => setSnackbar(false)}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarText}
              </Alert>
            </Snackbar>
          </>
        )}
      </>
    </div>
  );
};
export default MaterialDetails;
