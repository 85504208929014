export const getUsuarisFormatterFromApi = (data) => {
    let usuaris = [];

    data["hydra:member"].forEach((item) => {
        usuaris.push(getUsuariFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        usuaris
    };
};

export const getUsuariFormatterFromApi = (item) => {
    return {
        id: item.idUsr,
        nomUsuari: item.username,
        contrasenya: item.password,
        level: item.level,
        roles: item.roles,
        randomUser: item.randomUser,
        alta: item.date,
        nompUser: item.nompUser,
        direccio: item.address,
        poblacio: item.poblacio,
        codiPostal: item.zipCode,
        telefon: item.telephone,
        actiu: item.activeUser,
    };
};

export const putUsuariFormatterToApi = (item) => {
    return {
        idUsr: item.id,
        username: item.nomUsuari,
        password: item.contrasenya,
        level: item.level.toString(),
        roles: item.roles,
        randomUser: item.randomUser,
        date: item.alta,
        nompUser: item.nompUser,
        address: item.direccio,
        poblacio: item.poblacio,
        zipCode: item.codiPostal,
        telephone: item.telefon,
        activeUser: item.actiu,
    };
};


export const postUsuariFormatterToApi = (item) => {
    return {
        username: item.nomUsuari,
        password: item.contrasenya,
        level: item.level.toString(),
        roles: item.roles,
        randomUser: item.randomUser,
        date: item.alta,
        nompUser: item.nompUser,
        address: item.direccio,
        poblacio: item.poblacio,
        zipCode: item.codiPostal,
        telephone: item.telefon,
        activeUser: item.actiu,
    };
};
