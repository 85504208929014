export const getCdsMonograficsFormatterFromApi = (data) => {
    let monografics = [];

    data["hydra:member"].forEach((item) => {
        monografics.push(getCdMonograficFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        monografics
    };
};

export const getCdMonograficFormatterFromApi = (item) => {
    return {
        id: item.rowid,
        codi: item.codi,
        any: item.any.toString(),
        titol: item.titol,
        cdId: item.cdId,
    };
};

export const putCdMonograficFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        cdId: parseInt(item.cdId),
    };
};


export const postCdMonograficFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        cdId: parseInt(item.cdId),
    };
};
