const columns = [
    {
        field: "vincleUrl",
        headerName: "URL",
        width: 250,
    },
    {
        field: "vincleNom",
        headerName: "Nom",
        width: 200,
    },
    {
        field: "vincleCategoria",
        headerName: "Categoria",
        width: 200,
    }
];
export default columns;
