import React from "react";
import {Outlet, Navigate} from "react-router-dom";

import DashboardLayout from "./components/Layout/Dashboard";
import Auth from "./components/Layout/Auth";
import Compositors from "./screens/Compositors/CompositorsScreen";
import CompositorDetails from "./screens/CompositorDetails/CompositorDetailsScreen";
import Titols from "./screens/Titols/TitolsScreen";
import TitolDetails from "./screens/TitolDetails/TitolDetailsScreen";
import Materials from "./screens/Materials/MaterialsScreen";
import MaterialDetails from "./screens/MaterialDetails/MaterialDetailsScreen";
import Enregistraments from "./screens/Enregistraments/EnregistramentsScreen";
import EnregistramentDetails from "./screens/EnregistramentDetails/EnregistramentDetailsScreen";
import Tracks from "./screens/Tracks/TracksScreen";
import TracksDetails from "./screens/TracksDetails/TrackDetailsScreen";
import Collaboradors from "./screens/Collaboradors/CollaboradorsScreen";
import CollaboradorDetails from "./screens/CollaboradorDetails/CollaboradorDetailsScreen";
import Abreviatures from "./screens/Abreviatures/AbreviaturesScreen";
import Usuaris from "./screens/Usuaris/UsuarisScreen";
import UsuariDetails from "./screens/UsuariDetails/UsuariDetailsScreen";
import Vincles from "./screens/Vincles/VinclesScreen";
import VincleDetails from "./screens/VincleDetails/VincleDetailsScreen";
import Blogs from "./screens/Blogs/BlogsScreen";
import BlogDetails from "./screens/BlogDetails/BlogDetailsScreen";
import Destacats from "./screens/Destacats/DestacatsScreen";
import DestacatDetails from "./screens/DestacatDetails/DestacatDetailsScreen";
import LoginScreen from "./screens/Login/LoginScreen";
import CdsMonografics from "./screens/CdsMonografics/CdsMonograficsScreen";
import CdsMonograficsDetails from "./screens/CdsMonograficsDetails/CdsMonograficsDetailsScreen";
import CdsAltres from "./screens/CdsAltres/CdsAltresScreen";
import CdsAltresDetails from "./screens/CdsAltresDetails/CdsAltresDetails";
import CdsActuacionsAltres from "./screens/CdsActuacionsAltres/CdsActuacionsAltresScreen";
import CdsActuacionsAltresDetails from "./screens/CdsActuacionsAltresDetails/CdsActuacionsAltresDetails";

const routes = [
    { path: '/', element: <Navigate to="/login" /> },
    {
        path: "/login",
        element: <Auth/>,
        children: [{index: true, element: <LoginScreen/>}],
    },
    {
        path: "/",
        element: <DashboardLayout/>,
        children: [
            {
                path: "/compositors",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Compositors/>,
                    },
                    {path: ":id", element: <CompositorDetails/>},
                ],
            },
            {
                path: "/titols",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Titols/>,
                    },
                    {path: ":id", element: <TitolDetails/>},
                    {path: ":id/materials", element: <Materials/>},
                    {path: ":id/materials/:id", element: <MaterialDetails/>},
                ],
            },
            {
                path: "/enregistraments",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Enregistraments/>,
                    },
                    {path: ":id", element: <EnregistramentDetails/>},
                    {path: ":id/tracks", element: <Tracks/>},
                    {path: ":id/tracks/:id", element: <TracksDetails/>},
                ],
            },
            {
                path: "/collaboradors",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Collaboradors/>,
                    },
                    {path: ":id", element: <CollaboradorDetails/>},
                ],
            },
            {
                path: "/abreviatures",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Abreviatures/>,
                    },
                ],
            },
            {
                path: "/abreviatures/cds-monografics",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <CdsMonografics/>,
                    },
                    {path: ":id", element: <CdsMonograficsDetails/>},
                ],
            },
            {
                path: "/abreviatures/cds-altres",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <CdsAltres/>,
                    },
                    {path: ":id", element: <CdsAltresDetails/>},
                ],
            },
            {
                path: "/abreviatures/cds-actuacions-altres",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <CdsActuacionsAltres/>,
                    },
                    {path: ":id", element: <CdsActuacionsAltresDetails/>},
                ],
            },
            {
                path: "/usuaris",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Usuaris/>,
                    },
                    {path: ":id", element: <UsuariDetails/>},
                ],
            },
            {
                path: "/vincles",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Vincles/>,
                    },
                    {path: ":id", element: <VincleDetails/>},
                ],
            },
            {
                path: "/blogs",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Blogs/>,
                    },
                    {path: ":id", element: <BlogDetails/>},
                ],
            },
            {
                path: "/destacats",
                element: <Outlet/>,
                children: [
                    {
                        index: true,
                        element: <Destacats/>,
                    },
                    {path: ":id", element: <DestacatDetails/>},
                ],
            },
        ],
    },
];

export default routes;
