import {
    createCdAltreApi,
    getCdAltreApi,
    getCdsAltresApi,
    updateCdAltreApi,
    deleteCdAltreApi
} from "../../api/data/cdsAltresApi";
import {
    getCdAltreFormatterFromApi,
    getCdsAltresFormatterFromApi,
    postCdAltreFormatterToApi,
    putCdAltreFormatterToApi
} from "../../formatters/cdsAltresFormatter";


export const getCdsAltresService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getCdsAltresApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdsAltresFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all cds altres");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getCdAltreService = (cdAltreId) => {
    return new Promise((resolve, reject) => {
        getCdAltreApi(cdAltreId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdAltreFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single cd altre");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateCdAltreService = (cdAltreId, cdAltre) => {
    return new Promise((resolve, reject) => {
        updateCdAltreApi(cdAltreId, putCdAltreFormatterToApi(cdAltre))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdAltreFormatterFromApi(response));
                } else {
                    throw new Error("Error editing cdAltre");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createCdAltreService = (cdAltre) => {
    return new Promise((resolve, reject) => {
        createCdAltreApi(postCdAltreFormatterToApi(cdAltre))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getCdAltreFormatterFromApi(response));
                } else {
                    throw new Error("Error creating cdAltre");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};


export const deleteCdsAltresService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteCdAltreApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                           resolve();
                        } else {
                            throw new Error("Error deleting cdAltre " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
