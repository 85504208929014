export const getTracksFormatterFromApi = (data) => {
    let tracks = [];

    data["hydra:member"].forEach((item) => {
        tracks.push(getTrackFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        tracks
    };
};

export const getTrackFormatterFromApi = (item) => {
    return {
        id: item.idTrack,
        codi: item.codi,
        idCd: item.idCd,
        track: item.track,
        minut: item.minut,
        nom: item.nom,
        autor: item.autor,
        tipus: item.tipus,
        mp3: item.mp3,
    };
};

export const putTrackFormatterToApi = (item) => {
    return {
        idTrack: item.id,
        codi: item.codi,
        idCd: parseInt(item.idCd),
        track: parseInt(item.track),
        minut: item.minut,
        nom: item.nom,
        autor: item.autor,
        tipus: item.tipus,
        mp3: item.mp3,
    };
};


export const postTrackFormatterToApi = (item) => {
    return {
        idTrack: item.id,
        codi: item.codi,
        idCd: parseInt(item.idCd),
        track: parseInt(item.track),
        minut: item.minut,
        nom: item.nom,
        autor: item.autor,
        tipus: item.tipus,
        mp3: item.mp3,
    };
};
