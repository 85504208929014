import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomTextField, {
  CustomTextFieldTypes,
} from "../../components/composed/CustomTextField";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import { Alert } from "@mui/lab";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  getUsuariService,
  updateUsuariService,
} from "../../data/services/usuaris/usuarisService";
import poblacionsJson from "./../../poblacions.json";

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const getPoblacionsOptions = () => {
  let poblacionsNaixament = [];
  poblacionsJson.forEach((item) => {
    poblacionsNaixament.push({
      optionValue: item.poblacio,
    });
  });
  return getUniqueListBy(poblacionsNaixament, "optionValue");
};

const poblacions = getPoblacionsOptions();

const UsuariDetails = () => {
  const navigate = useNavigate();
  const [usuari, setUsuari] = useState({});

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarText, setSnackbarText] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { id } = useParams();
  const { state } = useLocation();

  const [inputs, setInputs] = useState({
    randomUser: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Usuari",
        disabled: true,
        value: "",
        onChange: handleChangeInputs,
      },
    },
    nomUsuari: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Nom",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    direccio: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Adreça",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    poblacio: {
      type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
      options: {
        label: "Població",
        value: "",
        onChange: handleChangeInputs,
        dropdownOptions: poblacions,
      },
    },
    codiPostal: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "C.P",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    telefon: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Telèfon",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    alta: {
      type: CustomTextFieldTypes.INPUT,
      options: {
        label: "Alta",
        value: "",
        onChange: handleChangeInputs,
      },
    },
    actiu: {
      type: CustomTextFieldTypes.CHECKBOX,
      options: {
        label: "Actiu",
        value: false,
        onChange: handleChecked,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    getUsuariService(id)
      .then((usuari) => {
        parseData(usuari);
        setIsLoading(false);
      })
      .catch(() => {
        setSnackbarSeverity("error");
        setSnackbarText("Hi ha hagut un error obtenint el usuari");
        setSnackbar(true);
        setIsLoading(false);
      });
  };

  const parseData = (usuari) => {
    const newInputs = { ...inputs };
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].options.value = usuari[key];
    });
    setInputs(newInputs);
    setUsuari(usuari);
  };

  function handleChangeInputs(event) {
    const value = event.target.value;
    const name = event.target.name;
    if (!inputs[name]) return;
    const newInputs = { ...inputs };
    newInputs[name].options.value = value;
    setInputs(newInputs);
  }

  function handleChecked(event) {
    const value = event.target.checked;
    const name = event.target.name;
    if (!inputs[name]) return;
    const newInputs = { ...inputs };
    newInputs[name].options.value = value;
    setInputs(newInputs);
  }

  function updateUsuari() {
    const updatedUsuari = usuari;
    Object.keys(inputs).forEach((key) => {
      updatedUsuari[key] = inputs[key].options.value;
    });
    setIsUpdating(true);

    updateUsuariService(id, updatedUsuari)
      .then((usuari) => {
        parseData(usuari);
        setSnackbarSeverity("success");
        setSnackbarText("usuari desat correctament");
        setSnackbar(true);
        setIsUpdating(false);
      })
      .catch(() => {
        setSnackbarSeverity("error");
        setSnackbarText("Hi ha hagut un error desant el usuari");
        setSnackbar(true);
        setIsUpdating(false);
      });
  }

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ mb: 5 }}
            variant="outlined"
            onClick={() => {
              navigate("/usuaris");
            }}
            style={{ display: "flex", gap: 8 }}
          >
            <ArrowBackIosNewIcon size={20} />
            ENRERE
          </Button>
          <Button
            sx={{ mb: 5 }}
            disabled={isUpdating}
            style={{ color: "white" }}
            variant="contained"
            onClick={updateUsuari}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              DESAR
              {isUpdating && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </div>
          </Button>
        </div>
        <Typography variant="h3" gutterBottom>
          Detalls de {state?.nom}
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
          <Link component={NavLink} to="/compositors">
            Músics per la cobla
          </Link>
          <Link component={NavLink} to="/usuaris">
            Usuaris
          </Link>
          <Typography>{state?.nom}</Typography>
        </Breadcrumbs>

        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <Card sx={{ mb: 5 }}>
              <CardContent>
                <Typography variant="h6" style={{ marginBottom: 8 }}>
                  Dades
                </Typography>
                <Grid container spacing={8}>
                  {Object.keys(inputs).map((key, index) => {
                    const input = inputs[key];
                    return (
                      <Grid key={index} item md={4} style={{ minWidth: 224 }}>
                        <CustomTextField
                          type={input.type}
                          options={{ ...input.options, name: key }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>

            <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={() => setSnackbar(false)}
            >
              <Alert
                onClose={() => setSnackbar(false)}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarText}
              </Alert>
            </Snackbar>
          </>
        )}
      </>
    </div>
  );
};
export default UsuariDetails;
