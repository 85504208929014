export const getCdsActuacionsFormatterFromApi = (data) => {
    let actuacions = [];

    data["hydra:member"].forEach((item) => {
        actuacions.push(getCdActuacioFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        actuacions
    };
};

export const getCdActuacioFormatterFromApi = (item) => {
    return {
        id: item.rowid,
        codi: item.codi,
        any: item.any?.toString(),
        titol: item.titol,
        extLink: item.extLink,
    };
};

export const putCdActuacioFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        extLink: item.extLink,
    };
};


export const postCdActuacioFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        extLink: item.extLink,
    };
};
