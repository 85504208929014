import {
    getTracksFormatterFromApi,
    getTrackFormatterFromApi,
    putTrackFormatterToApi,
    postTrackFormatterToApi
} from "./../../formatters/tracksFormatter";
import {createTrackApi, deleteTrackApi, getTrackApi, getTracksApi, updateTrackApi} from "../../api/data/tracksApi";


export const getTracksService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getTracksApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTracksFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all tracks");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getTrackService = (trackId) => {
    return new Promise((resolve, reject) => {
        getTrackApi(trackId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTrackFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single track");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateTrackService = (trackId, track) => {
    return new Promise((resolve, reject) => {
        updateTrackApi(trackId, putTrackFormatterToApi(track))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getTrackFormatterFromApi(response));
                } else {
                    throw new Error("Error editing track");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createTrackService = (track) => {
    return new Promise((resolve, reject) => {
        createTrackApi(postTrackFormatterToApi(track))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getTrackFormatterFromApi(response));
                } else {
                    throw new Error("Error creating track");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const deleteTracksService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteTrackApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                            resolve();
                        } else {
                            throw new Error("Error deleting track " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
