import {
    createCdMonograficApi,
    getCdMonograficApi,
    getCdsMonograficsApi,
    updateCdMonograficApi,
    deleteCdMonograficApi
} from "../../api/data/cdsMonograficsApi";
import {
    getCdMonograficFormatterFromApi,
    getCdsMonograficsFormatterFromApi,
    postCdMonograficFormatterToApi,
    putCdMonograficFormatterToApi
} from "../../formatters/cdsMonograficsFormatter";


export const getCdsMonograficsService = (page, search, itemsPerPage) => {
    return new Promise((resolve, reject) => {
        getCdsMonograficsApi(page, search, itemsPerPage)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdsMonograficsFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all cdMonografics");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const getCdMonograficService = (cdMonograficId) => {
    return new Promise((resolve, reject) => {
        getCdMonograficApi(cdMonograficId)
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdMonograficFormatterFromApi(response));
                } else {
                    throw new Error("Error getting a single cdMonografics");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const updateCdMonograficService = (cdMonograficId, cdMonografic) => {
    return new Promise((resolve, reject) => {
        updateCdMonograficApi(cdMonograficId, putCdMonograficFormatterToApi(cdMonografic))
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getCdMonograficFormatterFromApi(response));
                } else {
                    throw new Error("Error editing cdMonografic");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const createCdMonograficService = (cdMonografic) => {
    return new Promise((resolve, reject) => {
        createCdMonograficApi(postCdMonograficFormatterToApi(cdMonografic))
            .then((res) => {
                if (res.status === 201) {
                    const response = res.data;
                    if (response) resolve(getCdMonograficFormatterFromApi(response));
                } else {
                    throw new Error("Error creating cdMonografic");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};


export const deleteCdsMonograficsService = (ids) => {
    const promises = [];
    ids.forEach(id => {
        promises.push(
            new Promise((resolve, reject) => {
                deleteCdMonograficApi(id)
                    .then((res) => {
                        if (res.status === 204) {
                           resolve();
                        } else {
                            throw new Error("Error deleting cdMonografic " + id);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            })
        )
    })

    return Promise.all(promises)
        .then(r => {
            return r
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
}
