import axiosService from "./../../services/axiosService";

const headers = {
    "Content-Type": "multipart/form-data",
};

export const getDestacatsApi = (page = 1, search, itemsPerPage) => {
    let url = "/destacats?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const deleteDestacatApi = (destacatId) => {
    return axiosService.delete(`/destacats/${destacatId}`);
};

export const getDestacatApi = (destacatId) => {
    return axiosService.get(`/destacats/${destacatId}`);
};

export const updateDestacatApi = (destacatId, destacat) => {
    return axiosService.put(`/destacats/${destacatId}`, destacat);
};

export const createDestacatApi = (destacat) => {
    return axiosService.post(`/destacats`, destacat);
};

export const uploadDestacatFilesApi = (file, fileName) => {
    return axiosService.post("/data/upload", file, fileName, {headers: headers});
};
