import axiosService from "../../services/axiosService";

export const getCdsActuacionsApi = (page = 1, search, itemsPerPage) => {
    let url = "/cds-actuacions-altres?page=" + page;
    if (itemsPerPage) {
        url += "&itemsPerPage=" + itemsPerPage
    }
    if (search) {
        url += "&search=" + search;
    }
    return axiosService.get(url);
};

export const getCdActuacioApi = (cdActuacioId) => {
    return axiosService.get(`/cds-actuacions-altres/${cdActuacioId}`);
};

export const updateCdActuacioApi = (cdActuacioId, cdActuacio) => {
    return axiosService.put(`/cds-actuacions-altres/${cdActuacioId}`, cdActuacio);
};

export const createCdActuacioApi = (cdActuacio) => {
    return axiosService.post(`/cds-actuacions-altres`, cdActuacio);
};

export const deleteCdActuacioApi = (cdActuacioId) => {
    return axiosService.delete(`/cds-actuacions-altres/${cdActuacioId}`);
};
