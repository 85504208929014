const columns = [
    {
        field: "codi",
        headerName: "Codi",
        width: 90,
    },
    {
        field: "any",
        headerName: "Any",
        width: 90,
    },
    {
        field: "titol",
        headerName: "Títol",
        width: 350,
    },
    {
        field: "extLink",
        headerName: "Link",
        width: 90,
    },
];
export default columns;
