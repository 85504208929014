const columns = [
    {
        field: "track",
        headerName: "Track",
        width: 90,
    },
    {
        field: "minut",
        headerName: "Minut",
        width: 90,
    },
    {
        field: "nom",
        headerName: "Nom",
        width: 250,
    },
    {
        field: "codi",
        headerName: "Codi obra",
        width: 150,
    },
    {
        field: "autor",
        headerName: "Autor",
        width: 200,
    }
];
export default columns;
