import {
    getMaterialsDescripcioFormatterFromApi,
} from "../../formatters/materialsDescripcioFormatter";
import { getMaterialsDescripcioApi } from "../../api/data/materialsDescripcioApi";


export const getMaterialsDescripcioService = () => {
    return new Promise((resolve, reject) => {
        getMaterialsDescripcioApi()
            .then((res) => {
                if (res.status === 200) {
                    const response = res.data;
                    if (response) resolve(getMaterialsDescripcioFormatterFromApi(response));
                } else {
                    throw new Error("Error getting all descriptions");
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};
