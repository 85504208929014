import {Book, GraphicEq, InsertLink, ListAlt, People, Person, Star,} from "@mui/icons-material";

const pagesSection = [
    {
        href: "/compositors",
        icon: Person,
        title: "COMPOSITORS",
    },
    {
        href: "/titols",
        icon: ListAlt,
        title: "TÍTOLS",
    },
    {
        href: "/enregistraments",
        icon: GraphicEq,
        title: "ENREGISTRAMENTS",
    },
    {
        href: "/collaboradors",
        icon: People,
        title: "COL·LABORADORS",
    },
    {
        href: "/usuaris",
        icon: Person,
        title: "USUARIS",
    },
    {
        href: "/vincles",
        icon: InsertLink,
        title: "VINCLES",
    },
    {
        href: "/blogs",
        icon: Book,
        title: "BLOG",
    },
    {
        href: "/destacats",
        icon: Star,
        title: "DESTACATS",
    },
];

const navItems = [
    {
        pages: pagesSection,
    },
];
export default navItems;
