export const getCdsAltresFormatterFromApi = (data) => {
    let altres = [];

    data["hydra:member"].forEach((item) => {
        altres.push(getCdAltreFormatterFromApi(item));
    });

    return {
        count: data['hydra:totalItems'],
        altres
    };
};

export const getCdAltreFormatterFromApi = (item) => {
    return {
        id: item.rowid,
        codi: item.codi,
        any: item.any?.toString(),
        titol: item.titol,
        cdId: item.cdId,
    };
};

export const putCdAltreFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        cdId: parseInt(item.cdId),
    };
};


export const postCdAltreFormatterToApi = (item) => {
    return {
        rowid: item.id,
        codi: item.codi,
        any: parseInt(item.any),
        titol: item.titol,
        cdId: parseInt(item.cdId),
    };
};
