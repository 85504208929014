export const getAllTipusFormatterFromApi = (data) => {
    let tipus = [];

    data.forEach((item) => {
        tipus.push(getTipusFormatterFromApi(item));
    });

    return tipus;
};

export const getTipusFormatterFromApi = (item) => {
    return {
        type: item.c_tipus,
        descripcio: item.descrip,
        nivell: item.nivell
    };
};
