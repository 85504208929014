import React, {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {createEnregistramentService, getEnregistramentService, updateEnregistramentService} from "../../data/services/enregistraments/enregistramentsService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {Breadcrumbs, Button, Card, CardContent, CircularProgress, Grid, Snackbar, Typography} from "@mui/material";
import CircularIndeterminate from "../../components/unit/CircularProgress";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import UploadFile from "../../components/unit/UploadFile";
import {Alert} from "@mui/lab";

const EnregistramentDetails = () => {
    const navigate = useNavigate();
    const [enregistrament, setEnregistrament] = useState({});
    
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [snackbarText, setSnackbarText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const {id} = useParams();
    const {state} = useLocation();
    
    const [inputs, setInputs] = useState({
        ordre: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "ordre",
                disabled: true,
                value: "",
                onChange: handleChangeInputs
            }
        },
        referencia: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Referència",
                value: "",
                onChange: handleChangeInputs
            }
        },
        director: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Direcció",
                value: "",
                onChange: handleChangeInputs
            }
        },
        col: {
            type: CustomTextFieldTypes.SELECT,
            options: {
                label: "Col·lecció",
                value: "",
                dropdownOptions: [
                    {optionItem: "Col·leció clàssics", optionValue: 1 }, 
                    {optionItem: "Col·leció altres cobles", optionValue: 2}, 
                    {optionItem: "Enregistraments de la CCC", optionValue: 3}],
                onChange: handleChangeInputs
            }
        },
        data: {
            type: CustomTextFieldTypes.YEAR,
            options: {
                label: "Any",
                value: "",
                onChange: handleChangeInputs
            }
        },
        nom: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nom",
                value: "",
                onChange: handleChangeInputs
            }
        },
        cobla: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Tipus",
                value: "",
                onChange: handleChangeInputs
            }
        },
        linkSpotify: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "URL spotify",
                value: "",
                onChange: handleChangeInputs
            }
        },
        monografic: {
            type: CustomTextFieldTypes.CHECKBOX,
            options: {
                label: "Monogràfic",
                value: "",
                onChange: handleChecked
            }
        }
    })

    useEffect(() => {
        if (id !== "new") fetchData()
    }, [id]);

    const fetchData = () => {
        setIsLoading(true)
        getEnregistramentService(id)
            .then((enregistrament) => {
                parseData(enregistrament)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Hi ha hagut un error obtenint el enregistrament");
                setSnackbar(true);
                setIsLoading(false)
            })
    }

    const parseData = (enregistrament) => {
        const newInputs = {...inputs};
        Object.keys(newInputs).forEach((key) => {
            newInputs[key].options.value = enregistrament[key];
        })
        setInputs(newInputs)
        setEnregistrament(enregistrament)
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function updateEnregistrament() {
        const updatedEnregistrament = enregistrament;
        Object.keys(inputs).forEach((key) => {
            updatedEnregistrament[key] = inputs[key].options.value;
        })
        setIsUpdating(true);

        if (id === "new") {
            createEnregistramentService(updatedEnregistrament)
                .then((enregistrament) => {
                    parseData(enregistrament)
                    setSnackbarSeverity("success");
                    setSnackbarText("enregistrament desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                    navigate(`/enregistraments/${enregistrament.id}`, {state: {nom: enregistrament.nom}});
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error creant el enregistrament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        } else {
            updateEnregistramentService(id, updatedEnregistrament)
                .then((enregistrament) => {
                    parseData(enregistrament)
                    setSnackbarSeverity("success");
                    setSnackbarText("Enregistrament desat correctament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
                .catch(() => {
                    setSnackbarSeverity("error");
                    setSnackbarText("Hi ha hagut un error desant el enregistrament");
                    setSnackbar(true);
                    setIsUpdating(false);
                })
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        setEnregistrament({
            ...enregistrament,
            [name]: value
        })
    }

    function handleChecked(event) {
        const value = event.target.checked;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    return (
        <div>
        <Tabs aria-label="nav tabs example" value={0} sx={{mb: 10}}>
            <Tab label="Editar enregistrament"/>
            <Tab label="Tracks" component={Link} to="tracks"/>
        </Tabs>
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <Button sx={{mb: 5}} variant="outlined" onClick={() => {
                    navigate("/enregistraments")
                }} style={{display: "flex", gap: 8}}>
                    <ArrowBackIosNewIcon size={20}/>
                    ENRERE
                </Button>
                <Button
                    sx={{mb: 5}}
                    disabled={isUpdating}
                    style={{color: 'white'}}
                    variant="contained"
                    onClick={updateEnregistrament}
                >
                    <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                        DESAR
                        {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
                    </div>
                </Button>
            </div>
            <Typography variant="h3" gutterBottom>
                Detalls de {state?.nom}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                <Link component={NavLink} to="/compositors">
                    Músics per la cobla
                </Link>
                <Link component={NavLink} to="/enregistraments">
                    Enregistrament
                </Link>
                <Typography>{state?.nom}</Typography>
            </Breadcrumbs>

            {isLoading ? (
                <CircularIndeterminate/>
            ) : (
                <>

                    <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 8}}>
                                Dades
                            </Typography>
                            <Grid container spacing={8}>
                                {Object.keys(inputs).map((key, index) => {
                                        const input = inputs[key];
                                        return (
                                            <Grid key={index} item md={4} style={{minWidth: 224}}>
                                                <CustomTextField type={input.type}
                                                                 options={{...input.options, name: key}}/>
                                            </Grid>
                                        )
                                    }
                                )}
                            </Grid>
                        </CardContent>
                    </Card>


                    <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Crèdits"
                            value={enregistrament?.credits}
                            handleChange={handleChange}
                            name="credits"
                        />
                    </Card>
                    <Card sx={{mb: 5}}>
                        <ReactQuillEditor
                            titol="Comentaris"
                            value={enregistrament?.comentaris}
                            handleChange={handleChange}
                            name="comentaris"
                        />
                    </Card>

                    <Card sx={{mb: 5}}>
                        <CardContent>
                            <Typography variant="h6" style={{marginBottom: 15}}>
                                Imatges
                            </Typography>
                            <Grid mt={3} container spacing={12} ml={5} mb={20}>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={enregistrament.portada}
                                        id="portada"
                                        name="portada"
                                        alt="portada"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={enregistrament.contra}
                                        id="contra"
                                        name="contra"
                                        alt="contra portada"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <UploadFile
                                        handleChange={handleChange}
                                        image={enregistrament.galeta}
                                        id="galeta"
                                        name="galeta"
                                        alt="galeta"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>


                    <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                        <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    </div>
    );
};
export default EnregistramentDetails;
